<template>
  <div class="w-10 h-10 rounded-full overflow-clip">
    <img v-if="imageUrl" :src="imageUrl" class="object-cover w-full h-full" />
    <div v-else-if="text" class="grid w-full h-full bg-blue-300 place-items-center">
      <span class="font-bold text-blue-900">
        {{ text.toUpperCase() }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    text: String,
  },
}
</script>
