import { client, apiError } from "../../api/_client"
import Tag from "../models/Tag"

async function getTags(): Promise<Tag[]> {
  try {
    const response = await client.get("tag?$expand=connection_environments")
    return response.data.records.map((each: any) => Tag.fromResponse(each))
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getTag(id: string): Promise<Tag> {
  try {
    const response = await client.get(`tag(${id})`)
    return Tag.fromResponse(response.data)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function createTag(newTag: New<Tag>): Promise<string> {
  try {
    const response = await client.post("tag", new Tag(newTag).oDataJson())
    return response.headers["odata-entityid"]
  } catch (error) {
    throw apiError(error, {})
  }
}

async function deleteTag(id: string): Promise<void> {
  try {
    await client.delete(`tag(${id})`)
  } catch (error) {
    throw apiError(error, {})
  }
}

export { getTags, getTag, createTag, deleteTag }
