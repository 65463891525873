import { createStore } from "vuex"

import app from "./modules/app"
import platforms from "../platform/store/platform"
import environment from "../environment/store/environment"

import { Platform, Listener } from "@/platform"

const store = createStore<any>({
  getters: {
    isConstantLoaded(_, getters): boolean {
      return getters.isLoaded && getters.isEnvironmentLoaded
    },
  },
  modules: {
    app,
    platforms,
    environment,
  },
})

function platformById(id: string): Platform {
  return store.getters.platformById(id)
}

function listenersByPlatformId(id: string | null): Record<string, Listener> {
  return id === null ? store.getters.orphanListeners : platformById(id).listeners
}

export { platformById, listenersByPlatformId }

export default store
