import { toRecord } from "@/utilities/functions"
import Rules from "./Rules"
import Option from "./Option"

class Schema {
  private constructor(
    readonly id: string,
    readonly logicalName: string,
    readonly displayName: Translatable,
    readonly description: Translatable,
    readonly entity: {
      readonly description: Translatable
    } | null,
    readonly type: number | undefined,
    readonly format: string | undefined,
    readonly platformId: string | null, // platform id of listener
    readonly listenerId: string | null,
    readonly datasourceId: string | null,
    readonly content: Content | undefined
  ) {}

  get look(): { icon: string; color: string; rotate?: boolean } {
    switch (this.logicalName) {
      case "Simple":
        return { icon: "alt_route", color: "bg-orange-500", rotate: true }
      case "Transform":
        return { icon: "shape_line", color: "bg-indigo-500" }
      case "GenerateInvoices":
        return { icon: "receipt_long", color: "bg-teal-500" }
      default:
        return { icon: "build", color: "bg-gray-500" }
    }
  }

  static decode(data: any, platformId: string | null): Schema {
    return new Schema(
      data.idschema,
      data.name,
      JSON.parse(data.display),
      JSON.parse(data.description),
      data.entity ? JSON.parse(data.entity) : null,
      data.type,
      data.format,
      platformId,
      data.idlistener ?? null,
      data.iddata_source ?? null,
      !data.content ? undefined : Content.decode(JSON.parse(data.content))
    )
  }
}

type _Source = "manual" | "input" | "metadata" | "picklist" | "local_input"

class Content {
  private constructor(
    private readonly _fixedMapping: Record<string, _FixedMapping> | null,
    private readonly _dynamicMapping: _Mapping | null,
    private readonly _fixedMatching: Record<string, _FixedMatching> | null,
    private readonly _dynamicMatching: _Matching | null
  ) {}

  private _fixedMappingInfos: Record<string, FixedMappingInfo> | null | undefined
  get fixedMappingInfos(): Record<string, FixedMappingInfo> | null {
    if (this._fixedMappingInfos === null) {
      return null
    }
    return (this._fixedMappingInfos ??= this._fixedMapping)
  }

  private _dynamicMappingInfo: MappingInfo | null | undefined
  get dynamicMappingInfo(): MappingInfo | null {
    if (this._dynamicMappingInfo === null) {
      return null
    }
    return (this._dynamicMappingInfo ??= this._dynamicMapping)
  }

  private _fixedMatchingInfos: Record<string, FixedMatchingInfo> | null | undefined
  get fixedMatchingInfos(): Record<string, FixedMatchingInfo> | null {
    return this._fixedMatchingInfos === null
      ? null
      : (this._fixedMatchingInfos ??= this._fixedMatching)
  }

  private _dynamicMatchingInfo: MatchingInfo | null | undefined
  get dynamicMatchingInfo(): MatchingInfo | null {
    if (this._dynamicMatchingInfo === null) {
      return null
    }
    return (this._dynamicMatchingInfo ??= this._dynamicMatching)
  }

  static decode(data: any): Content {
    const functions = data.functions ? toRecord(data.functions, "name") : null
    return new Content(
      !data.fixed_step_parameters
        ? null
        : toRecord(
            data.fixed_step_parameters.map((each: any) => _FixedMapping.decode(each, functions)),
            "logical"
          ),
      !data.dynamic_step_parameters ? null : _Mapping.decode(data.dynamic_step_parameters),
      !data.fixed_matching_parameters
        ? null
        : toRecord(
            data.fixed_matching_parameters.map((each: any) => _FixedMatching.decode(each)),
            "logical"
          ),
      !data.dynamic_matching_parameters ? null : _Matching.decode(data.dynamic_matching_parameters)
    )
  }
}

class _FixedMapping {
  private constructor(
    readonly logical: string,
    readonly display: Translatable,
    readonly description: Translatable,
    readonly type: {
      readonly type: string
    },
    readonly input: {
      readonly type: string
      readonly optional: boolean
      readonly sources: _Source[]
    } | null,
    readonly defaultValue: {
      readonly type: string
      readonly optional: boolean
    } | null,
    readonly subFixedMappingInfos: Record<string, _FixedMapping> | null,
    readonly subDynamicMappingInfo: // TODO: rename to subMapping
    | ({
          readonly type: string
          readonly optional: boolean
          readonly rules: Rules
        } & _Mapping)
      | null
  ) {}

  static decode(data: any, functions: Record<string, any> | null): _FixedMapping {
    const input = data.variable?.input_field
    const def = data.variable?.default
    const subFixed = data.fixed.step_parameters
    const subDyn = data.variable?.step_parameters
    return new _FixedMapping(
      data.fixed.output_field,
      data.display,
      data.description,
      {
        type: data.fixed.type,
      },
      input
        ? {
            type: input.type,
            optional: input.nullable,
            sources: input.sourceTypes,
            // rules: Rules.decode(input.rules, constants),
          }
        : null,
      def
        ? {
            type: def.type,
            optional: def.nullable,
          }
        : null,
      subFixed
        ? toRecord(
            subFixed.map((each: any) => _FixedMapping.decode(each, functions)),
            "logical"
          )
        : null,
      subDyn
        ? {
            type: subDyn.type,
            optional: subDyn.nullable,
            rules: Rules.decode(subDyn.rules, functions),
            ..._Mapping.decode(subDyn),
          }
        : null
    )
  }
}

class _Mapping {
  private constructor(
    readonly output: OutputInfo,
    readonly input: InputInfo | null,
    readonly defaultValue: DefaultInfo | null
  ) {}

  static decode(data: any): _Mapping {
    const output = data.elements.output_field
    const input = data.elements.input_field
    const def = data.elements.default
    return new _Mapping(
      {
        display: output.display,
        description: output.description,
        type: output.type,
        optional: output.nullable,
        sources: output.sourceTypes,
        options: output.picklistSettings
          ? _OptionDecoder.decode(output.picklistSettings.picklistOptions)
          : null,
      },
      input
        ? {
            display: input.display,
            description: input.description,
            type: input.type,
            optional: input.nullable,
            sources: input.sourceTypes,
          }
        : null,
      def
        ? {
            display: def.display,
            description: def.description,
            type: def.type,
            optional: def.nullable,
          }
        : null
    )
  }
}

class _FixedMatching {
  private constructor(
    readonly logical: string,
    readonly display: Translatable,
    readonly description: Translatable,
    readonly operator: string,
    readonly input: {
      readonly type: string
      readonly optional: boolean
      readonly sources: _Source[]
    } | null,
    readonly defaultValue: {
      readonly type: string
      readonly optional: boolean
    } | null
  ) {}

  static decode(data: any): _FixedMatching {
    const input = data.variable?.input_field
    const def = data.variable?.default
    return new _FixedMatching(
      data.fixed.output_field,
      data.display,
      data.description,
      data.fixed.operaotr,
      input
        ? {
            type: input.type,
            optional: input.nullable,
            sources: input.sourceTypes,
            // rules: Rules.decode(input.rules, constants),
          }
        : null,
      def
        ? {
            type: def.type,
            optional: def.nullable,
          }
        : null
    )
  }
}

class _Matching {
  private constructor(
    readonly operand: OutputInfo,
    readonly operator: _Operator,
    readonly input: InputInfo | null,
    readonly defaultValue: DefaultInfo | null
  ) {}

  static decode(data: any): _Matching {
    const output = data.elements.output_field
    const input = data.elements.input_field
    const def = data.elements.default
    return new _Matching(
      {
        display: output.display,
        description: output.description,
        type: output.type,
        optional: output.nullable,
        sources: output.sourceTypes,
        options: output.picklistSettings
          ? _OptionDecoder.decode(output.picklistSettings.picklistOptions)
          : null,
      },
      _Operator.decode(data.elements.operator),
      input
        ? {
            display: input.display,
            description: input.description,
            type: input.type,
            optional: input.nullable,
            sources: input.sourceTypes,
          }
        : null,
      def
        ? {
            display: def.display,
            description: def.description,
            type: def.type,
            optional: def.nullable,
          }
        : null
    )
  }
}

class _Operator {
  private constructor(
    private readonly _data: Record<string, Record<string, Option>>,
    public optional: boolean,
    public readonly allOptions: Record<string, Option>
  ) {}

  static decode(data: any): _Operator {
    const allOptions: Record<string, Option> = {}
    const _data = data.picklistSettings.picklistOptions.reduce(
      (types: Record<string, any>, each: any) => {
        const options = _OptionDecoder.decode(each.options)
        Object.assign(allOptions, options)
        types[each.type] = options
        return types
      },
      {}
    )
    return new _Operator(_data, data.nullable, allOptions)
  }

  optionsForType(type: string): Record<string, Option> {
    return this._data[type]
  }
}

class _OptionDecoder {
  static decode(data: Array<any>): Record<string, Option> {
    return data.reduce((options: Record<string, Option>, each) => {
      options[each.technical_value] = {
        optionLogical: each.technical_value,
        optionDisplay: each.display,
        description: each.description ?? null,
      }
      return options
    }, {})
  }
}

type MappingInfo = {
  readonly output: OutputInfo
  readonly input: InputInfo | null
  readonly defaultValue: DefaultInfo | null
}

type FixedMappingInfo = {
  readonly logical: string
  readonly display: Translatable
  readonly description: Translatable
  readonly type: {
    readonly type: string
  }
  readonly input: {
    readonly type: string
    readonly optional: boolean
    readonly sources: _Source[]
  } | null
  readonly defaultValue: {
    readonly type: string
    readonly optional: boolean
  } | null
  readonly subFixedMappingInfos: Record<string, FixedMappingInfo> | null
  readonly subDynamicMappingInfo:
    | ({
        readonly type: string
        readonly optional: boolean
        readonly rules: Rules
      } & MappingInfo)
    | null
}

type MatchingInfo = {
  readonly operand: OutputInfo
  readonly operator: _Operator
  readonly input: InputInfo | null
  readonly defaultValue: DefaultInfo | null
}

type FixedMatchingInfo = {
  readonly logical: string
  readonly display: Translatable
  readonly description: Translatable
  readonly operator: string
  readonly input: {
    readonly type: string
    readonly optional: boolean
    readonly sources: _Source[]
  } | null
  readonly defaultValue: {
    readonly type: string
    readonly optional: boolean
  } | null
}

type OutputInfo = {
  readonly display: Translatable
  readonly description: Translatable
  readonly type: string
  readonly optional: boolean
  readonly sources: _Source[]
  readonly options: Record<string, Option> | null
}

type InputInfo = {
  readonly display: Translatable
  readonly description: Translatable
  readonly type: string
  readonly optional: boolean
  readonly sources: _Source[]
}

// type TypeInfo = {
//   readonly display: Translatable
//   readonly description: Translatable
//   readonly type: string
//   readonly optional: boolean
//   readonly sources: _Source[]
// }

// type LookupInfo = {
//   readonly display: Translatable
//   readonly description: Translatable
//   readonly type: string
//   readonly optional: boolean
//   readonly sources: _Source[]
// }

type DefaultInfo = {
  readonly display: Translatable
  readonly description: Translatable
  readonly type: string
  readonly optional: boolean
}

export default Schema
export { Content, FixedMappingInfo, MappingInfo, FixedMatchingInfo, MatchingInfo }
