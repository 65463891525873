import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-10 flex" }
const _hoisted_2 = ["id", "type", "value", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_input_wrapper = _resolveComponent("w-input-wrapper")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createBlock(_component_v_date_picker, {
    class: "h-full",
    modelValue: _ctx.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
    mode: "time",
    color: "red",
    "is-required": ""
  }, {
    default: _withCtx(({ inputValue, togglePopover }) => [
      _createVNode(_component_w_input_wrapper, {
        id: _ctx._id,
        label: _ctx.label,
        disabled: _ctx.disabled,
        optional: _ctx.optional,
        plain: _ctx.plain,
        errorMessage: _ctx.errorMessage,
        hint: _ctx.hint
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "leading", {
              focus: () => _ctx.$refs.theInput.focus()
            }),
            _createElementVNode("input", {
              class: "grow focus:border-none focus:ring-0 border-none bg-transparent placeholder:text-gray-400 px-3",
              ref: "theInput",
              id: _ctx._id,
              type: _ctx.type,
              readonly: "",
              value: inputValue,
              onClick: ($event: any) => (togglePopover())
            }, null, 8, _hoisted_2),
            _renderSlot(_ctx.$slots, "trailing", {
              focus: () => _ctx.$refs.theInput.focus()
            })
          ])
        ]),
        _: 2
      }, 1032, ["id", "label", "disabled", "optional", "plain", "errorMessage", "hint"])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}