import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "type"]
const _hoisted_2 = { class: "flex items-center justify-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_spinner = _resolveComponent("w-spinner")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
      'border-[1.5px] tracking-wide min-w-[72px] rounded-md',
      _ctx.small ? 'py-1 px-3' : 'py-2 px-4',
      'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-700',
      _ctx.classes,
    ]),
    disabled: _ctx.disabled || !_ctx.clickEnabled,
    type: _ctx.submitter ? 'submit' : 'button',
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_w_spinner, {
            key: 0,
            small: "",
            class: _normalizeClass(_ctx.spinnerClasses)
          }, null, 8, ["class"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 10, _hoisted_1))
}