
import { defineComponent } from "vue"

import WInputWrapper from "./WInputWrapper.vue"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: String,
    id: String,
    label: String,
    placeholder: String,
    hint: String,
    warningMessage: String,
    errorMessage: String,
    disabled: Boolean,
    optional: Boolean,
    readonly: Boolean,
    rows: {
      type: Number,
      default: 3,
    },
    grows: Boolean,
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
  },
  mounted() {
    this.resizeHeight()
  },
  methods: {
    resizeHeight() {
      const element = this.$refs.textarea as any
      element.style.height = "auto"
      if (this.grows == false) {
        element.style.height = element.scrollHeight
      } else {
        element.style.height = element.scrollHeight + "px"
      }
      this.$emit("update:modelValue", element.value)
    },
  },
  components: {
    WInputWrapper,
  },
})
