import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-x-1 mb-1 flex items-center w-fit"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_4 = {
  key: 1,
  class: "mt-2 flex items-center gap-1"
}
const _hoisted_5 = { class: "text-sm text-amber-500" }
const _hoisted_6 = {
  key: 2,
  class: "mt-2 flex items-center gap-1"
}
const _hoisted_7 = { class: "text-sm text-orange-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_hint_icon = _resolveComponent("w-hint-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: "text-gray-800 tracking-wide text-[15px]"
          }, _toDisplayString(_ctx.label), 9, _hoisted_2),
          (!_ctx.optional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true),
          (_ctx.hint)
            ? (_openBlock(), _createBlock(_component_w_hint_icon, {
                key: 1,
                content: _ctx.hint
              }, null, 8, ["content"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.classes, "overflow-clip"])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (_ctx.warningMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.warningMessage), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}