
import { defineComponent, PropType } from "vue"

import WInputWrapper from "./WInputWrapper.vue"

export default defineComponent({
  emits: ["update:modelValue", "focus", "blur", "esc", "enter", "up", "down", "tab"],
  props: {
    id: String,
    label: String,
    modelValue: String,
    type: {
      type: String as PropType<"email" | "number" | "password" | "search" | "tel" | "text" | "url">,
      default: "text",
    },
    autocomplete: Boolean,
    placeholder: String,
    plain: Boolean,
    warningMessage: String,
    errorMessage: String,
    hint: null as null | Translatable,
    disabled: Boolean,
    optional: Boolean,
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
  },
  components: {
    WInputWrapper,
  },
})
