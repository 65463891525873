
import { defineComponent, PropType } from "vue"
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue"

import WInputWrapper from "./WInputWrapper.vue"
import { WSpinner, WIcon, WChip } from "@/components/misc"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    id: String,
    label: String,
    modelValue: {
      type: null as unknown as PropType<any | Array<any> | null>,
      required: true,
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    returnKey: String,
    itemKey: String,
    itemText: {
      type: String,
      default: "text",
    },
    itemSubText: String,
    placeholder: String,
    hint: String,
    errorMessage: String,
    placement: {
      type: String,
      default: "right",
    },
    plain: Boolean,
    loading: Boolean,
    disabled: Boolean,
    optional: Boolean,
    fixed: Boolean,
  },
  watch: {
    selectedItems: {
      handler() {
        this.search = ""
        let values: Array<any> = []
        this.selectedItems.forEach((each: any) => values.push(each[this.returnKey!]))
        this.$emit("update:modelValue", values)
      },
    },
  },
  data() {
    return {
      search: null as string | null,
      rect: undefined as any,
      selectedItems: [] as Array<any>,
    }
  },
  mounted() {
    this.setRect()

    if (this.modelValue) {
      this.selectedItems = this.returnKey
        ? this.items.filter(
            (eachItem) =>
              this.modelValue.some((eachModel: any) => eachItem[this.returnKey!] === eachModel)!
          )!
        : this.modelValue
    }
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },

    searchedItem(): any[] {
      if (!this.search || this.search === "") {
        return this.items
      }
      const search = this.search.toLowerCase().replace(/\s+/g, "")
      return this.items.filter((each) => {
        return (
          (each[this.itemText] &&
            each[this.itemText].toLowerCase().replace(/\s+/g, "").includes(search)) ||
          (this.itemSubText &&
            each[this.itemSubText] &&
            each[this.itemSubText].toLowerCase().replace(/\s+/g, "").includes(search))
        )
      })
    },
    optionsPosition(): string | undefined {
      if (!this.bottomGap) {
        return undefined
      }
      if (this.bottomGap > 440) {
        return "bottom"
      }
      return window.innerWidth - this.rect.right > 400 ? "right" : "left"
    },
    bottomGap(): number | undefined {
      if (!this.rect) {
        return undefined
      }
      return window.innerHeight - this.rect.bottom
    },
  },
  methods: {
    setRect() {
      const combobox = this.$refs.combobox as any
      this.rect = combobox.$el.getBoundingClientRect()
    },

    updateSelected(item: any) {
      let values: any = this.modelValue

      // Check returnKey=
      this.returnKey
        ? // if returnKey is set
          item.forEach((eachItem: any) => {
            // Remove from array, if the value exist
            if (values.some((eachValue: any) => eachValue === eachItem[this.returnKey!])) {
              // values.filter((eachValue:any) => eachValue != eachItem[this.returnKey!])
            }
            // Push the item into array, if the value dose not exist.
            else {
              values.push(eachItem[this.returnKey!])
            }
          })
        : // if returnKey is not set
          item

      // Emit the values
      this.$emit("update:modelValue", values)
    },

    removeFromItems(itemId: any) {
      this.selectedItems = this.selectedItems.filter((item: any) => item[this.returnKey!] != itemId)
    },
  },
  components: {
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    WInputWrapper,
    WSpinner,
    WIcon,
    WChip,
  },
})
