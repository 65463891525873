import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between items-center"
}
const _hoisted_3 = {
  key: 1,
  class: "space-y-6"
}
const _hoisted_4 = {
  key: 0,
  class: "flex justify-items-start items-center"
}
const _hoisted_5 = {
  key: 1,
  class: "space-y-6"
}
const _hoisted_6 = {
  key: 0,
  class: "flex justify-between items-center"
}
const _hoisted_7 = {
  key: 1,
  class: "space-y-6"
}
const _hoisted_8 = {
  key: 0,
  class: "flex justify-start items-start"
}
const _hoisted_9 = {
  key: 1,
  class: "space-y-6"
}
const _hoisted_10 = {
  key: 0,
  class: "flex justify-items-start items-center"
}
const _hoisted_11 = {
  key: 1,
  class: "space-y-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_text_skeleton = _resolveComponent("w-text-skeleton")!
  const _component_w_input_skeleton = _resolveComponent("w-input-skeleton")!
  const _component_w_button_skeleton = _resolveComponent("w-button-skeleton")!
  const _component_w_icon_skeleton = _resolveComponent("w-icon-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skeletons, (skeleton, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        (skeleton.component === 'item')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (skeleton.horizontal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: i,
                        class: "h-20 bg-gray-200 rounded animate-pulse"
                      }))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: i,
                        class: "h-20 bg-gray-200 rounded animate-pulse"
                      }))
                    }), 128))
                  ]))
            ], 64))
          : _createCommentVNode("", true),
        (skeleton.component === 'text')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (skeleton.horizontal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (i) => {
                      return (_openBlock(), _createBlock(_component_w_text_skeleton, {
                        key: i,
                        large: skeleton.large,
                        short: skeleton.short,
                        class: "inline-block mr-1 last:mr-0"
                      }, null, 8, ["large", "short"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (i) => {
                      return (_openBlock(), _createBlock(_component_w_text_skeleton, {
                        key: i,
                        large: skeleton.large,
                        short: skeleton.short
                      }, null, 8, ["large", "short"]))
                    }), 128))
                  ]))
            ], 64))
          : _createCommentVNode("", true),
        (skeleton.component === 'input')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (skeleton.horizontal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (i) => {
                      return (_openBlock(), _createBlock(_component_w_input_skeleton, {
                        key: i,
                        label: skeleton.label,
                        numberOfLines: skeleton.numberOfLines,
                        class: "mr-1 inline-block last:mr-0 w-full"
                      }, null, 8, ["label", "numberOfLines"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (i) => {
                      return (_openBlock(), _createBlock(_component_w_input_skeleton, {
                        key: i,
                        label: skeleton.label,
                        numberOfLines: skeleton.numberOfLines
                      }, null, 8, ["label", "numberOfLines"]))
                    }), 128))
                  ]))
            ], 64))
          : _createCommentVNode("", true),
        (skeleton.component === 'button')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              (skeleton.horizontal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (i) => {
                      return (_openBlock(), _createBlock(_component_w_button_skeleton, {
                        key: i,
                        class: "inline-block mr-4 last:mr-0"
                      }))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (skeletonButton) => {
                      return (_openBlock(), _createBlock(_component_w_button_skeleton, { key: skeletonButton }))
                    }), 128))
                  ]))
            ], 64))
          : _createCommentVNode("", true),
        (skeleton.component === 'icon')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
              (skeleton.horizontal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (i) => {
                      return (_openBlock(), _createBlock(_component_w_icon_skeleton, {
                        key: i,
                        square: skeleton.square,
                        class: "inline-block mr-1 last:mr-0"
                      }, null, 8, ["square"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skeleton.count, (i) => {
                      return (_openBlock(), _createBlock(_component_w_icon_skeleton, {
                        key: i,
                        square: skeleton.square
                      }, null, 8, ["square"]))
                    }), 128))
                  ]))
            ], 64))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}