
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    label: {
      type: Boolean,
      default: false,
    },
    numberOfLines: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    height() {
      return `${Math.max(this.numberOfLines, 1) * 42}px`
    },
  },
})
