import { client, unauthedClient, apiError } from "../../api/_client"
import tokenKeeper from "../_tokenkeeper"

import Credential from "../models/Credential"
import RegistrationData from "../models/RegistrationData"

async function register(data: RegistrationData): Promise<void> {
  try {
    await unauthedClient.post("register", data.encode())
    await login(new Credential(data.credential.email, data.credential.password))
  } catch (error) {
    throw apiError(error, {})
  }
}

async function login(credential: Credential): Promise<void> {
  try {
    const response = await unauthedClient.post("login", credential.encode())
    tokenKeeper.setMyTokens(response.data)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function loginWithMFA(credential: Credential, mfa: string): Promise<void> {
  try {
    const cred = credential.encode()
    cred.code = mfa
    const response = await unauthedClient.post("login", cred)
    tokenKeeper.setMyTokens(response.data)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function impersonate(params: { tenantId: string; userId?: string }): Promise<void> {
  try {
    const data = {
      idtenant: params.tenantId,
    } as any
    if (params.userId) {
      data.iduser_impersonated = params.userId
    }
    const response = await client.post("getThirdPartyTenantToken", data)
    tokenKeeper.setImpersonatedTokens(response.data)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function preEnableMfa(): Promise<{ qrCode: string }> {
  try {
    const response = await client.post("preEnableMFA")
    return { qrCode: response.data.qrcode }
  } catch (error) {
    throw apiError(error, {})
  }
}

async function enableMfa(code: string): Promise<void> {
  try {
    await client.post("enableMFA", { code })
  } catch (error) {
    throw apiError(error, {})
  }
}

async function changePassword(params: {
  password: string
  newPassword: string
  mfaCode?: string
}): Promise<void> {
  try {
    const data = {
      password: params.password,
      newPassword: params.newPassword,
    } as any
    if (params.mfaCode) {
      data.code = params.mfaCode
    }
    await client.post("changePassword", data)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function requestResetPassword(email: string): Promise<void> {
  try {
    await unauthedClient.post("resetPassword", { email })
  } catch (error) {
    throw apiError(error, {})
  }
}

async function resetPassword(params: {
  email: string
  passwordCode: string
  newPassword: string
}): Promise<void> {
  try {
    await unauthedClient.post("changePasswordAfterReset", params)
  } catch (error) {
    throw apiError(error, {})
  }
}

function logout() {
  tokenKeeper.removeMyTokens()
}

function stopImpersonation() {
  tokenKeeper.removeImpersonatedTokens()
}

export {
  register,
  login,
  loginWithMFA,
  impersonate,
  preEnableMfa,
  enableMfa,
  changePassword,
  requestResetPassword,
  resetPassword,
  logout,
  stopImpersonation,
}
