import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_icon_button = _resolveComponent("w-icon-button")!
  const _component_w_input = _resolveComponent("w-input")!

  return (_openBlock(), _createBlock(_component_w_input, {
    id: _ctx.id,
    label: _ctx.label,
    disabled: _ctx.disabled,
    optional: _ctx.optional,
    plain: _ctx.plain,
    type: _ctx.inputType,
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    autocomplete: _ctx.autocomplete,
    placeholder: _ctx.placeholder,
    warningMessage: _ctx.warningMessage,
    errorMessage: _ctx.errorMessage,
    hint: _ctx.hint
  }, {
    leading: _withCtx(({ focus }) => [
      _renderSlot(_ctx.$slots, "leading", { focus: focus }, undefined, true)
    ]),
    trailing: _withCtx(() => [
      (_ctx.type === 'password' && _ctx.modelValue != '')
        ? (_openBlock(), _createBlock(_component_w_icon_button, {
            key: 0,
            small: "",
            square: "",
            onClick: _ctx.switchPasswordVisibility
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.inputType === "password" ? "visibility" : "visibility_off"), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["id", "label", "disabled", "optional", "plain", "type", "modelValue", "autocomplete", "placeholder", "warningMessage", "errorMessage", "hint"]))
}