import { toRecord } from "@/utilities/functions"

export default class Method {
  constructor(
    readonly platformId: string | null,
    readonly listenerId: string,
    readonly listenerKey: string,
    readonly displayName: Translatable,
    readonly logicalName: string,
    readonly description: Translatable,
    readonly entities: any,
    readonly stepParameter: StepParameter | null,
    readonly matchingParameter: MatchingParameter | null
  ) {}

  static fromResponse(
    data: any,
    name: string,
    platformId: string | null,
    listenerId: string,
    listenerKey: string
  ): Method {
    return new Method(
      platformId,
      listenerId,
      listenerKey,
      data.display_name,
      name,
      data.description,
      data.entities,
      data.available_parameters?.step_parameters
        ? StepParameter.fromResponse(data.available_parameters.step_parameters)
        : null,
      data.available_parameters?.matching_parameters
        ? MatchingParameter.fromResponse(data.available_parameters.matching_parameters)
        : null
    )
  }
}

class StepParameter {
  constructor(
    public availableFields: StepFieldInfo,
    public canAdd: boolean,
    // public warning?: { major: [string]; minor: [string] },
    public readonly fixedMappingInfos: Record<string, FixedMappingInfo> | null,
    public baseList?: StepField[]
  ) {}

  static fromResponse(data: any): StepParameter {
    const available = data.available_fields
    return new StepParameter(
      {
        input: available.input_field,
        output: available.output_field,
        type: available.type,
        lookups: available.lookups,
        default: available.default,
      },
      data.canAdd ?? true,
      // data.warning,
      !data.baseList
        ? null
        : toRecord(
            data.baseList.map((each: any) => FixedMappingInfo.decode(each)),
            "logical"
          ),
      data.baseList?.map((each: any) => StepField.fromResponse(each))
    )
  }
}

class FixedMappingInfo {
  private constructor(
    public readonly logical: string, // output
    public readonly display: Translatable,
    public readonly description: Translatable,
    public readonly type: {
      readonly type: string
    },
    public readonly input: {
      readonly type: string
      readonly optional: boolean
      readonly sources: string[]
      readonly rules: Rule[]
    } | null,
    public readonly defaultValue: {
      readonly type: string
      readonly optional: boolean
    } | null,
    public readonly subMappingInfo:
      | ({
          readonly type: string
          readonly optional: boolean
          readonly rules: Rule[]
        } & MappingInfo)
      | null
  ) {}

  static decode(data: any): FixedMappingInfo {
    const sub = data.step_parameters
    return new FixedMappingInfo(
      data.output_field,
      data.display_name,
      data.description,
      {
        type: data.type ?? "text",
      },
      {
        type: data.type ?? "text",
        optional: data.optional,
        sources: [],
        rules: [],
      },
      {
        type: "text",
        optional: true,
      },
      sub
        ? {
            type: "array",
            optional: true,
            rules: [],
            ...MappingInfo.decode(sub),
          }
        : null
    )
  }
}

class MappingInfo {
  private constructor(
    public readonly output: {
      readonly displayName: Translatable
      readonly description: Translatable
      readonly type: string
      readonly optional: boolean
      readonly sources: string[]
      readonly options: Record<string, any> | null
      readonly rules: Rule[]
    },
    public readonly input: {
      readonly displayName: Translatable
      readonly description: Translatable
      readonly sources: string[] // TODO:
      readonly type: string
      readonly optional: boolean
      readonly rules: Rule[]
    },
    public readonly defaultValue: {
      readonly displayName: Translatable
      readonly description: Translatable
      readonly type: string
      readonly optional: boolean
    }
  ) {}

  static decode(data: any): MappingInfo {
    const output = data.available_fields.output_field
    const input = data.available_fields.input_field
    return new MappingInfo(
      {
        displayName: {
          key: "output",
          default: "Output",
        },
        description: output.description,
        type: "text",
        optional: false,
        sources: ["manual"],
        options: null,
        rules: [],
      },
      {
        displayName: {
          key: "input",
          default: "Input",
        },
        description: input.description,
        type: "text",
        optional: true,
        sources: [],
        rules: [],
      },
      {
        displayName: {
          key: "default",
          default: "Default",
        },
        description: {
          key: "default",
          default: "Default",
        },
        type: "text",
        optional: true,
      }
    )
  }
}

class Rule {
  private constructor(
    public readonly type: number,
    public readonly func: string,
    public readonly args: string[]
  ) {}

  static decode(data: any): Rule {
    return new Rule(data.type, data.function, data.arguments)
  }
}

class MatchingParameter {
  constructor(
    public availableFields: MatchingFieldInfo,
    public canAdd: boolean,
    // public warning?: { major: [string]; minor: [string] },
    public baseList?: MatchingField[]
  ) {}

  static fromResponse(data: any): MatchingParameter {
    const available = data.available_fields
    return new MatchingParameter(
      {
        input: available.input_field,
        output: available.output_field,
        type: available.type,
        operator: available.operator,
        default: available.default,
      },
      data.canAdd ?? true,
      // data.warning,
      data.baseList?.map((each: any) => MatchingField.fromResponse(each))
    )
  }
}

class StepField {
  private constructor(
    public readonly outputLogical: string,
    public readonly outputDisplay: Translatable,
    public readonly optional: boolean,
    public readonly description: Translatable,
    public readonly type: string,
    public readonly childParameter: StepParameter | null
  ) {}

  static fromResponse(data: any): StepField {
    return new StepField(
      data.output_field,
      data.display_name,
      data.optional,
      data.description,
      data.type,
      !data.step_parameters ? null : StepParameter.fromResponse(data.step_parameters)
    )
  }
}

class MatchingField {
  private constructor(
    public readonly outputLogical: string,
    public readonly outputDisplay: Translatable,
    public readonly optional: boolean,
    public readonly description: Translatable,
    public readonly type: string
  ) {}

  static fromResponse(data: any): MatchingField {
    return new MatchingField(
      data.output_field,
      data.display_name,
      data.optional,
      data.description,
      data.type
    )
  }
}

type StepFieldInfo = {
  readonly output?: FieldProperty
  readonly type?: FieldProperty
  readonly lookups?: FieldProperty
  readonly input?: FieldProperty
  readonly default?: FieldProperty
}

type MatchingFieldInfo = {
  readonly output?: FieldProperty
  readonly type?: FieldProperty
  readonly operator?: FieldProperty
  readonly input?: FieldProperty
  readonly default?: FieldProperty
}

type FieldProperty = {
  readonly source?: [string]
  // readonly autofill?: string
  // readonly filter?: string
  // readonly warning?: { major: [string]; minor: [string] }
  readonly description?: Translatable | null
  readonly list?: Record<string, Translatable>
  // sample: string | null
}
