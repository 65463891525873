
import { defineComponent, PropType } from "vue"
import { WIcon } from "@/components/misc"
import { WButton } from "@/components/form"
import WHintIcon from "@/components/tooltip/WHintIcon.vue"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object as PropType<Array<Date>>,
      required: true,
    },
    id: String,
    label: String,
    zIndex: String,
    placeholder: String,
    hint: null as null | Translatable,
    errorMessage: String,
    placement: {
      type: String,
      default: "bottom",
    },
    mode: {
      type: String,
      default: "dateTime",
    },
    plain: Boolean,
    loading: Boolean,
    disabled: Boolean,
    optional: Boolean,
    minDate: Date,
  },
  watch: {
    dates: {
      handler() {
        let dateArray: Array<Date> = []
        this.dates.forEach((date: { date: Date }) => {
          dateArray.push(date.date)
        })
        this.$emit("update:modelValue", dateArray)
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      selected: [] as Array<any>,
      dates: [
        {
          date: new Date(),
        },
      ] as any,
    }
  },
  beforeMount() {
    if (this.modelValue) {
      this.dates = this.modelValue.map((x: Date) => {
        return { date: x }
      })
    } else {
      this.dates = [
        {
          date: new Date(),
        },
      ]
    }
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
  },
  methods: {
    addDate() {
      this.dates.push({
        date: new Date(),
      })
      setTimeout(() => {
        this.$nextTick(() => {
          const chips = this.$refs.chip as any
          const chip = chips[chips.length - 1]
          chip.$el.click()
        })
      }, 100)
    },
    removeDate(date: any, hide: any) {
      this.dates = this.dates.filter((d: any) => d !== date)
      hide()
    },
    dateSelected(e: any, date: any, toggle: any) {
      toggle({ ref: e.target, placement: "bottom", transition: "none", showDelay: 0, hideDelay: 0 })
      this.selected = date
    },
  },
  components: {
    // WPopover,
    // PopoverPanel,
    // WTextField,
    WButton,
    WIcon,
    WHintIcon,
    // WInputWrapper,
  },
})
