import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-4" }
const _hoisted_2 = { class: "flex items-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex justify-between", _ctx.classes])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "left")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "right")
    ])
  ], 2))
}