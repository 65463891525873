
import { defineComponent } from "vue"
import { Line } from "vue-chartjs"
import ChartDataset from "./ChartDataset"

export default defineComponent({
  props: {
    data: {
      type: ChartDataset,
      required: true,
    },
  },
  components: { Line },
})
