import { required, helpers, minLength, email } from "@vuelidate/validators"
import Encoder from "@/utilities/Encoder"

export default class Credential {
  constructor(public email?: string, public password?: string) {}

  encode(): any {
    const encoder = new Encoder(this)
    encoder.encodeProps("email", "password")
    return encoder.json()
  }

  private _o = null

  static validations() {
    return {
      email: {
        email,
        required: helpers.withMessage("Please input your email address", required),
      },
      password: {
        required: helpers.withMessage("Please input your password", required),
        minLength: helpers.withMessage("Your password at least 8 characters", minLength(8)),
      },
    }
  }
}
