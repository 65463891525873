import { toRecord } from "@/utilities/functions"

import JsonFormsTemplates from "./JsonFormTemplates"

class Rules {
  get jsonFormTemplates(): JsonFormsTemplates | null {
    const rule = this._rules["enableAndDisplayFieldAsJsonSchema"]
    if (!rule) {
      return null
    }
    return new JsonFormsTemplates(rule.params!)
  }

  get valueRestrictor(): any | null {
    return this._rules["restrictNextValues"]?.params ?? null
  }

  private constructor(
    private readonly _rules: Record<
      string,
      {
        readonly func: string
        readonly args: string[] | null
        readonly params: any | null
      }
    >
  ) {}

  static decode(data: any, funcs: Record<string, any> | null): Rules {
    return new Rules(
      toRecord(
        data.map((each: any) => {
          return {
            func: each.function,
            args: each.arguments ?? null,
            params: each.parameters ?? funcs?.[each.function]?.parameters ?? null,
          }
        }),
        "func"
      )
    )
  }
}

// function restrictNextValues(
//   previousValue: string,
//   params: Record<string, string[]>
// ): string[] | null {
//   return params[previousValue] ?? null
// }

export default Rules
