import ChartData from "./ChartData"
import ChartDataset from "./ChartDataset"
import WBarChart from "./WBarChart.vue"
import WLineChart from "./WLineChart.vue"

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js"

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
)

export { ChartData, ChartDataset, WBarChart, WLineChart }
