import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-x-1 mb-1 flex items-center w-fit"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_4 = { class: "px-3 py-2 rounded-md border-2 border-dashed border-gray-300" }
const _hoisted_5 = ["for"]
const _hoisted_6 = ["id", "accept"]
const _hoisted_7 = {
  key: 1,
  class: "text-sm text-red-600 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_hint_icon = _resolveComponent("w-hint-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx._id,
            class: "text-gray-800 tracking-wide text-[15px]"
          }, _toDisplayString(_ctx.label), 9, _hoisted_2),
          (!_ctx.optional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true),
          (_ctx.hint)
            ? (_openBlock(), _createBlock(_component_w_hint_icon, {
                key: 1,
                content: _ctx.hint
              }, null, 8, ["content"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", {
        for: _ctx._id,
        class: "cursor-pointer rounded-md text-gray-600 focus-within:outline-none hover:text-primary-800 focus-within:ring-primary-700 focus-within:ring-2 focus-within:ring-offset-2"
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.modelValue?.name ?? _ctx.placeholder), 1),
        _createElementVNode("input", {
          id: _ctx._id,
          type: "file",
          accept: _ctx.accept,
          class: "sr-only",
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fileSelected($event.target.files)))
        }, null, 40, _hoisted_6)
      ], 8, _hoisted_5)
    ]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}