
import { defineComponent } from "vue"

import WButtonSkeleton from "./WButtonSkeleton.vue"
import WIconSkeleton from "./WIconSkeleton.vue"
import WInputSkeleton from "./WInputSkeleton.vue"
import WTextSkeleton from "./WTextSkeleton.vue"

export default defineComponent({
  props: {
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    skeletons() {
      return this.type.split(",").map((each) => {
        let count: number
        let componentString = each.trim()

        const horizontal = componentString.endsWith("-horizontal")
        componentString = componentString.replace("-horizontal", "")

        if (!componentString.includes("#")) {
          count = 1
        } else {
          const split = componentString.split("#")
          componentString = split[0]
          count = parseInt(split[1])
        }

        let component: object = {}
        if (componentString.startsWith("text")) {
          component = this.decodeText(componentString)
        }
        if (componentString.startsWith("input")) {
          component = this.decodeInput(componentString)
        }
        if (componentString.startsWith("button")) {
          component = this.decodeButton(componentString)
        }
        if (componentString.startsWith("icon")) {
          component = this.decodeIcon(componentString)
        }
        if (componentString.startsWith("item")) {
          component = { component: "item" }
        }
        return {
          ...component,
          count,
          horizontal,
        }
      })
    },
  },
  methods: {
    decodeIcon(str: string) {
      return {
        component: "icon",
        square: str.includes("-square"),
      }
    },
    decodeText(str: string) {
      return {
        component: "text",
        large: str.includes("-large"),
        short: str.includes("-short"),
      }
    },
    decodeInput(str: string) {
      const splits = str.split("-")
      let lines = 1
      for (const each of splits) {
        const number = parseInt(each)
        if (number) {
          lines = number
          break
        }
      }
      return {
        component: "input",
        numberOfLines: lines,
        label: !str.includes("plain"),
      }
    },
    decodeButton(_: string) {
      return {
        component: "button",
      }
    },
  },
  components: {
    WTextSkeleton,
    WButtonSkeleton,
    WInputSkeleton,
    WIconSkeleton,
  },
})
