
import { defineComponent } from "vue"
import WHintIcon from "../tooltip/WHintIcon.vue"

export default defineComponent({
  components: { WHintIcon },
  emits: ["update:modelValue"],
  props: {
    modelValue: Object,
    id: String,
    label: String,
    accept: String,
    errorMessage: String,
    hint: null as null | Translatable,
    optional: Boolean,
    placeholder: {
      type: String,
      default: "Upload a sample file",
    },
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
  },
  methods: {
    fileSelected(files: any[]) {
      this.$emit("update:modelValue", files.length > 0 ? files[0] : null)
    },
  },
})
