
import { defineComponent } from "vue"

import { getDataPoints } from "../api/statistics"
import ChartDataProvider from "../models/ChartDataProvider"

import { WBarChart, WLineChart } from "@/components/chart"
import { WSelector } from "@/components/form"
import WHintIcon from "@/components/tooltip/WHintIcon.vue"

export default defineComponent({
  data() {
    return {
      provider: new ChartDataProvider([]),
      selectedOption: 0,
      selectedTypeChart: 0,
    }
  },
  async mounted() {
    try {
      const data = await getDataPoints()
      this.provider = new ChartDataProvider(data)
    } catch (error) {
      console.log("Error", error)
    }
  },
  computed: {
    dateShow() {
      return [
        { value: 0, name: "Last 1 week" },
        { value: 1, name: "Last 1 month" },
        { value: 2, name: "Last 3 months" },
        { value: 3, name: "Last 1 year" },
      ]
    },
    typeChart() {
      return [
        { value: 0, name: "Line Chart" },
        { value: 1, name: "Bar Chart" },
      ]
    },
    classes() {
      if (this.selectedOption > 1) {
        return "grid gap-4 sm:grid-cols-1"
      } else {
        return "grid gap-4 xl:grid-cols-2 md:grid-cols-1"
      }
    },
    selectedChartData(): any {
      const currentDate = new Date()
      const lastOneWeek = new Date()
      lastOneWeek.setDate(currentDate.getDate() - 7)
      const lastOneMonth = new Date()
      lastOneMonth.setMonth(lastOneMonth.getMonth() - 1)
      const lastOneYear = new Date()
      lastOneYear.setFullYear(lastOneYear.getFullYear() - 1)
      const lastThreeMonth = new Date()
      lastThreeMonth.setMonth(lastThreeMonth.getMonth() - 3)

      switch (this.selectedOption) {
        case 1:
          return this.provider.getBetween(new Date(lastOneMonth), new Date(currentDate))
        case 2:
          return this.provider.getBetween(new Date(lastThreeMonth), new Date(currentDate))
        case 3:
          return this.provider.getBetween(new Date(lastOneYear), new Date(currentDate))
        default:
          return this.provider.getBetween(new Date(lastOneWeek), new Date(currentDate))
      }
    },
  },
  components: { WBarChart, WLineChart, WSelector, WHintIcon },
})
