import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid grid-rows-1"
}
const _hoisted_2 = { class: "grid grid-cols-2 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_text_field = _resolveComponent("w-text-field")!
  const _component_w_button = _resolveComponent("w-button")!
  const _component_w_form = _resolveComponent("w-form")!

  return (_openBlock(), _createBlock(_component_w_form, null, _createSlots({
    default: _withCtx(() => [
      (!_ctx.isImpersonating)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_w_text_field, {
                label: "First Name",
                modelValue: _ctx.profileInfo.firstName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.profileInfo.firstName) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_w_text_field, {
                label: "Last Name",
                modelValue: _ctx.profileInfo.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.profileInfo.lastName) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isImpersonating)
        ? (_openBlock(), _createBlock(_component_w_text_field, {
            key: 1,
            label: "Email",
            modelValue: _ctx.profileInfo.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.profileInfo.email) = $event)),
            disabled: ""
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_w_text_field, {
        label: "Company",
        modelValue: _ctx.profileInfo.company,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.profileInfo.company) = $event)),
        disabled: ""
      }, null, 8, ["modelValue"])
    ]),
    _: 2
  }, [
    (!_ctx.isImpersonating)
      ? {
          name: "actions",
          fn: _withCtx(() => [
            _createVNode(_component_w_button, { onClick: _ctx.submit }, {
              default: _withCtx(() => [
                _createTextVNode("Submit")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_w_button, {
              outlined: "",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('discard')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Discard")
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}