<template>
  <footer class="px-2">
    <div class="flex justify-end items-center text-sm border-t border-gray-200 py-2 px-4 gap-1">
      <span>© {{ new Date().getFullYear() }}</span> <span>Powered by</span>
      <a class="underline underline-offset-1 text-primary-900" href="http://wiresk.com" target="_"
        >Wiresk</a
      >
    </div>
  </footer>
</template>
