
import { defineComponent } from "vue"
import WSpinner from "../misc/WSpinner.vue"

export default defineComponent({
  components: { WSpinner },
  emits: ["click"],
  props: {
    submitter: Boolean,
    block: Boolean,
    disabled: Boolean,
    clickEnabled: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    small: Boolean,
    primary: Boolean,
    gray: Boolean,
    outlined: Boolean,
    shy: Boolean,
  },
  computed: {
    isPrimary() {
      return this.primary || !(this.outlined || this.shy)
    },
    isGray() {
      return !this.primary && this.gray
    },
    isOutlined() {
      return !this.primary && this.outlined
    },
    isShy() {
      return !this.primary && this.shy
    },
    classes() {
      return {
        "disabled:opacity-50": this.disabled,
        "w-full": this.block,
        "text-white bg-primary-900 border-primary-900 enabled:hover:bg-primary-800": this.isPrimary,
        "text-gray-600 bg-gray-100 enabled:hover:text-gray-700 enabled:hover:bg-gray-200":
          this.isGray,
        "text-gray-700 border-gray-300 enabled:hover:bg-gray-50": this.isOutlined,
        "text-gray-600 enabled:hover:text-gray-900 enabled:hover:bg-gray-100": this.isShy,
      }
    },
    spinnerClasses() {
      return {
        "border-gray-700": this.isShy || this.isOutlined,
        "border-white": this.isPrimary,
      }
    },
  },
})
