import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-medium uppercase text-xs tracking-wider text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_bar = _resolveComponent("w-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_w_bar, {
      dense: "",
      indent: false
    }, {
      left: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
        _renderSlot(_ctx.$slots, "bar.left")
      ]),
      _: 3
    }),
    _renderSlot(_ctx.$slots, "default")
  ]))
}