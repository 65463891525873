import { useCookies } from "vue3-cookies"

type Tokens = {
  request: string | null
  renew: string
}

class TokenKeeper {
  private _cookies = useCookies().cookies

  private get _myTokens(): Tokens | null {
    const renew = this._cookies.get("renewToken")
    return !renew
      ? null
      : {
          request: this._cookies.get("requestToken") ?? null,
          renew,
        }
  }
  get hasTokens(): boolean {
    return this._impersonatedTokens !== null || this._myTokens !== null
  }

  private get _impersonatedTokens(): Tokens | null {
    const renew = this._cookies.get("impersonatedRenewToken")
    return !renew
      ? null
      : {
          request: this._cookies.get("impersonatedRequestToken") ?? null,
          renew,
        }
  }
  get hasImpersonatedTokens(): boolean {
    return this._impersonatedTokens !== null
  }

  async getRequestToken(renewFunc: (renewToken: string) => Promise<any>): Promise<string | null> {
    const impersonatedToken = await this._getImpersonatedRequestToken(renewFunc)
    if (impersonatedToken) {
      return impersonatedToken
    }

    const tokens = this._myTokens
    if (!tokens) {
      return null
    }
    if (tokens.request) {
      return tokens.request
    }
    const newTokens = await renewFunc(tokens.renew)
    if (!newTokens) {
      this.removeMyTokens()
      return null
    }
    this.setMyTokens(newTokens)
    return this._myTokens!.request
  }

  private async _getImpersonatedRequestToken(
    renewFunc: (renewToken: string) => Promise<any>
  ): Promise<string | null> {
    const tokens = this._impersonatedTokens
    if (!tokens) {
      return null
    }
    if (tokens.request) {
      return tokens.request
    }
    const newTokens = await renewFunc(tokens.renew)
    if (!newTokens) {
      this.removeImpersonatedTokens()
      return null
    }
    this.setImpersonatedTokens(newTokens)
    return this._impersonatedTokens!.request
  }

  setMyTokens(tokens: any) {
    const domain = location.host.includes("localhost")
      ? undefined
      : this.removeFirstLevelSubdomain(location.host)
    const requestExpiration = new Date(tokens.requestToken_Expiration * 1000)
    this._cookies.set("requestToken", tokens.requestToken, requestExpiration, undefined, domain)
    const renewExpiration = new Date(tokens.renewToken_Expiration * 1000)
    this._cookies.set("renewToken", tokens.renewToken, renewExpiration, undefined, domain)
  }

  setImpersonatedTokens(tokens: any) {
    const domain = location.host.includes("localhost")
      ? undefined
      : this.removeFirstLevelSubdomain(location.host)
    const requestExpiration = new Date(tokens.requestToken_Expiration * 1000)
    this._cookies.set(
      "impersonatedRequestToken",
      tokens.requestToken,
      requestExpiration,
      undefined,
      domain
    )
    const renewExpiration = new Date(tokens.renewToken_Expiration * 1000)
    this._cookies.set(
      "impersonatedRenewToken",
      tokens.renewToken,
      renewExpiration,
      undefined,
      domain
    )
  }

  removeRequestToken() {
    const domain = location.host.includes("localhost")
      ? undefined
      : this.removeFirstLevelSubdomain(location.host)
    if (this.hasImpersonatedTokens) {
      this._cookies.remove("impersonatedRequestToken", undefined, domain)
    } else {
      this._cookies.remove("requestToken", undefined, domain)
    }
  }

  removeMyTokens() {
    const domain = location.host.includes("localhost")
      ? undefined
      : this.removeFirstLevelSubdomain(location.host)
    this._cookies.remove("requestToken", undefined, domain)
    this._cookies.remove("renewToken", undefined, domain)
  }

  removeImpersonatedTokens() {
    const domain = location.host.includes("localhost")
      ? undefined
      : this.removeFirstLevelSubdomain(location.host)
    this._cookies.remove("impersonatedRequestToken", undefined, domain)
    this._cookies.remove("impersonatedRenewToken", undefined, domain)
  }

  private removeFirstLevelSubdomain(domain: string): string {
    const domainParts = domain.split(".")
    if (domainParts.length < 3) {
      return domain
    }
    const newDomainParts = domainParts.slice(1)
    return newDomainParts.join(".")
  }
}

export default new TokenKeeper()
