import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/logos/wiresk-full.png'
import _imports_1 from '@/assets/logos/wiresk.png'


const _hoisted_1 = { class: "h-20 grid place-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "w-40",
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  class: "h-6",
  src: _imports_1
}
const _hoisted_4 = { class: "relative grow mt-4 space-y-8 overflow-scroll" }
const _hoisted_5 = {
  key: 0,
  class: "px-2 text-xs font-bold text-gray-500 uppercase"
}
const _hoisted_6 = { class: "mt-2 space-y-2" }
const _hoisted_7 = {
  key: 0,
  class: "text-sm select-none"
}
const _hoisted_8 = { class: "pt-2 border-t border-gray-100" }
const _hoisted_9 = {
  key: 0,
  class: "select-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_icon = _resolveComponent("w-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_w_icon_button = _resolveComponent("w-icon-button")!
  const _component_settings_page = _resolveComponent("settings-page")!
  const _component_w_dialog = _resolveComponent("w-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["h-screen z-10 flex flex-col p-2 bg-white border-r border-r-gray-100", [_ctx.isCompact ? 'w-16' : 'w-72 fixed md:static']])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.isCompact)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : (_openBlock(), _createElementBlock("img", _hoisted_3))
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.internalGroups, (group) => {
          return (_openBlock(), _createElementBlock("div", {
            key: group.name
          }, [
            (group.title)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(this.isCompact ? "" : group.title), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: item.title,
                  to: `/${_ctx.$i18n.locale + item.to}`,
                  "active-class": `bg-primary-50 text-primary-900`,
                  class: "flex items-center p-3 rounded-md cursor-pointer hover:bg-primary-50 hover:text-primary-900"
                }, {
                  default: _withCtx(({ isActive }) => [
                    _createVNode(_component_w_icon, {
                      class: "mr-6 text-[18px]",
                      outlined: !isActive
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.icon), 1)
                      ]),
                      _: 2
                    }, 1032, ["outlined"]),
                    (!_ctx.isCompact)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.title), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
            ])
          ]))
        }), 128)),
        _createVNode(_component_w_icon_button, {
          class: _normalizeClass(["fixed bottom-20 translate-x-7 bg-white shadow border border-gray-200", [_ctx.isCompact ? 'left-3.5' : 'left-[237px]']]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('toggleSidebar')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isCompact ? "chevron_right" : "chevron_left"), 1)
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = true)),
          class: "flex p-3 rounded-md cursor-pointer hover:bg-gray-100"
        }, [
          _createVNode(_component_w_icon, { class: "mr-6 text-gray-600" }, {
            default: _withCtx(() => [
              _createTextVNode("account_circle")
            ]),
            _: 1
          }),
          (!_ctx.isCompact)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Account"))
            : _createCommentVNode("", true)
        ])
      ])
    ], 2),
    _createVNode(_component_w_dialog, {
      padding: false,
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_settings_page, {
          class: "w-[50vw] h-[75vh]",
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = false))
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}