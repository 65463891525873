import tokenKeeper from "./_tokenkeeper"
import Credential from "./models/Credential"
import RegistrationData from "./models/RegistrationData"

function isAuthed(): boolean {
  return tokenKeeper.hasTokens
}

function isImpersonating(): boolean {
  return tokenKeeper.hasImpersonatedTokens
}

export { isAuthed, isImpersonating, Credential, RegistrationData }
export * from "./api/auth"
