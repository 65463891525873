export default class User {
  private constructor(
    public readonly id: string,
    public firstName: string,
    public lastName: string,
    public readonly email: string,
    public readonly company: string, // TODO: temp
    public readonly mfaEnabled: boolean
  ) {}

  static fromResponse(data: any): User {
    return new User(
      data.iduser,
      data.first_name,
      data.last_name,
      data.username,
      data.tenant.name,
      data.mfa_enabled
    )
  }

  oDataJson(): any {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
    }
  }
}

// interface Address {
//   line1: string
//   line2: string
//   city: string
//   country: string
//   postcode: string
// }
