import Axios from "axios"
import tokenKeeper from "../auth/_tokenkeeper"

import router from "../router"

const client = Axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
})

client.interceptors.request.use(
  async (config) => {
    // Error here is thrown from renewToken. We simply rethrow it.
    const token = await tokenKeeper.getRequestToken(renewToken)
    if (!token) {
      // Note: We replace home instead of login because this might happen during impersonation.
      // So we go to home of admin.
      // TODO: But here doesn't seem to be the place for routing.
      router.replace({ name: "home" })
      // TODO: find a proper way to cancel api request.
      // Returning undefined doesn't stop the request
      return undefined
    }
    config.headers!.Authorization = `Bearer ${token}`
    return config
  },
  (error) => Promise.reject(error)
)

client.interceptors.response.use(
  async (response) => response,
  (error) => {
    if (error.response?.status === 401 && error.config) {
      // Unauthorized. Invalid request token.
      tokenKeeper.removeRequestToken()
      return client.request(error.config)
    }
    return Promise.reject(error)
  }
)

async function renewToken(renewToken: string) {
  try {
    const _client = Axios.create({
      baseURL: process.env.VUE_APP_ENDPOINT,
    })
    return (await _client.post("renewToken", { longToken: renewToken })).data
  } catch (error: any) {
    if (error.response?.data.error_code === 600015) {
      // Invalid renew token
      return null
    }
    throw error
  }
}

function apiError(error: any, errorMessages: Record<number, string>): ApiError {
  if (!error.response) {
    if (!navigator.onLine) {
      return ApiError.noNetworkConnection()
    }
    return new ApiError(false, "Something went wrong", null, null, null)
  }
  const apiError = ApiError.fromResponse(error.response)
  const message = errorMessages[apiError.serverCode!] ?? errorMessages[apiError.httpCode!]
  if (message) {
    apiError.message = message
  }
  return apiError
}

class ApiError implements Translatable {
  constructor(
    public readonly noNetworkConnection: boolean,
    public message: string,
    public readonly httpCode: number | null,
    public readonly serverCode: number | null,
    public readonly serverMessage: string | null
  ) {}

  get default(): string {
    return this.message
  }

  get key(): string {
    return `ERROR_${this.serverCode}`
  }

  static noNetworkConnection(): ApiError {
    return new ApiError(true, "No network connection", null, null, null)
  }

  static fromResponse(response: any): ApiError {
    return new ApiError(
      false,
      response.data.message,
      response.status,
      response.data.error_code,
      response.data.message
    )
  }
}

const unauthedClient = Axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
})

export { client, unauthedClient, apiError }
