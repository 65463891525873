
import { defineComponent } from "vue"
import { Dropdown } from "floating-vue"

import { WIcon } from "@/components/misc"
import WHintIcon from "../tooltip/WHintIcon.vue"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    label: String,
    optional: Boolean,
    disabled: Boolean,
    hint: null as null | Translatable,
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    errorMessage: String,
  },
  methods: {
    select(option: any) {
      const index = this.modelValue.indexOf(option)
      if (index !== -1) {
        const values = [...this.modelValue]
        values.splice(index, 1)

        this.$emit("update:modelValue", values)
      } else {
        this.$emit("update:modelValue", [...this.modelValue, option])
      }
    },
  },
  components: {
    Dropdown,
    WIcon,
  },
})
