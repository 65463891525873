import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "disabled", "checked"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_hint_icon = _resolveComponent("w-hint-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['inline-flex items-center gap-2 py-2', _ctx.precedingLabel ? 'flex-row-reverse' : ''])
  }, [
    _createElementVNode("input", {
      class: _normalizeClass([
        'border-gray-300 rounded cursor-pointer focus-visible:border-primary-700 focus:ring-primary-800',
        _ctx.disabled ? 'text-gray-300' : 'text-primary-900',
      ]),
      type: "checkbox",
      id: _ctx._id,
      disabled: _ctx.disabled,
      checked: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.checked)))
    }, null, 42, _hoisted_1),
    _createElementVNode("label", {
      for: _ctx._id,
      class: _normalizeClass([
        'cursor-pointer tracking-wide text-[15px]',
        _ctx.disabled ? 'text-gray-300' : 'text-gray-800',
      ])
    }, _toDisplayString(_ctx.label), 11, _hoisted_2),
    (_ctx.hint)
      ? (_openBlock(), _createBlock(_component_w_hint_icon, {
          key: 0,
          content: _ctx.hint
        }, null, 8, ["content"]))
      : _createCommentVNode("", true)
  ], 2))
}