import { client, apiError } from "./_client"
import SubTenant from "@/models/SubTenant"

async function isAdminTenant(): Promise<boolean> {
  try {
    const tenantId = (await client.post("whoAmI")).data.tenantId
    const response = await client.get(`country/$count?$filter=iddefault_tenant eq '${tenantId}'`)
    return response.data === 1
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getSubTenant(): Promise<SubTenant[]> {
  try {
    const response = await client.get("getAdministrableTenants")
    return response.data.map((each: any) => SubTenant.decode(each))
  } catch (error) {
    throw apiError(error, {})
  }
}

export { isAdminTenant, getSubTenant }
