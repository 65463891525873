export default {
  state: {
    compactSideBar: false,
    hiddenAppBar: false,
  },
  getters: {
    isCompactSideBar(state: any) {
      return state.compactSideBar
    },
    isAppBarHidden(state: any) {
      return state.hiddenAppBar
    }
  },
  mutations: {
    shrinkSidebar(state: any) {
      if (window.innerWidth < 1024) {
        state.compactSideBar = false
      } else {
        state.compactSideBar = true
      }
    },
    toggleSidebar(state: any) {
      state.compactSideBar = !state.compactSideBar
    },
    showAppBar(state: any) {
      state.hiddenAppBar = false
    },
    hideAppBar(state: any) {
      state.hiddenAppBar = true
    }
  },
}
