
import { defineComponent } from "vue"
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue"

import { WActionDialog } from "@/components/modals"
import { WIconButton, WButton } from "@/components/buttons"
import { WIcon, WSpinner } from "@/components/misc"

import ProfileForm from "./ProfileForm.vue"
import ChangePasswordForm from "./ChangePasswordForm.vue"
import SubTenantList from "./SubTenantList.vue"

import { isAdminTenant } from "@/api/tenant"
import { logout, isImpersonating, stopImpersonation } from "@/auth"

export default defineComponent({
  emits: ["close"],
  data() {
    return {
      confirmLogout: false,
      stopImpersonationDialog: false,
      tabs: undefined as undefined | any[],
      selectedIndex: 0,
    }
  },
  computed: {
    isImpersonating(): boolean {
      return isImpersonating()
    },
  },
  mounted() {
    this.getTabs()
  },
  methods: {
    async getTabs() {
      const tabs = [
        {
          icon: "account_circle",
          title: "Profile",
        },
      ]
      if (!this.isImpersonating) {
        tabs.push({
          icon: "lock_open",
          title: "Change password",
        })
      }
      if (await isAdminTenant()) {
        tabs.push({
          icon: "store",
          title: "Subtenants",
        })
      }
      this.tabs = tabs
    },
    logout() {
      logout()
      this.$router.push("/login")
    },
    async stopImpersonation() {
      stopImpersonation()
      location.href = location.origin
    },
  },
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    WActionDialog,
    WButton,
    WIconButton,
    WIcon,
    WSpinner,
    ProfileForm,
    ChangePasswordForm,
    SubTenantList,
  },
})
