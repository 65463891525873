import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4 z-10 sticky top-0 bg-white text-xl capitalize"
}
const _hoisted_2 = { class: "relative px-4" }
const _hoisted_3 = {
  key: 0,
  class: "absolute inset-0 z-10 bg-white/75 grid place-items-center"
}
const _hoisted_4 = { class: "space-y-6" }
const _hoisted_5 = {
  key: 1,
  class: "mt-4 p-4 bg-white sticky bottom-0 border-t-[1.5px] border-gray-100 flex space-x-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_skeleton = _resolveComponent("w-skeleton")!
  const _component_w_spinner = _resolveComponent("w-spinner")!
  const _component_w_alert_description = _resolveComponent("w-alert-description")!
  const _component_w_alert = _resolveComponent("w-alert")!
  const _component_w_button = _resolveComponent("w-button")!
  const _component_w_action_dialog = _resolveComponent("w-action-dialog")!

  return (_ctx.skeletonLoading)
    ? (_openBlock(), _createBlock(_component_w_skeleton, {
        key: 0,
        type: "text-large-short,input#2,button#2-horizontal",
        class: "p-4"
      }))
    : (_openBlock(), _createElementBlock("form", {
        key: 1,
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.trySubmit && _ctx.trySubmit(...args)), ["prevent"]))
      }, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.loading || _ctx.disabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_w_spinner, {
                      key: 0,
                      small: "",
                      class: "border-gray-600"
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", { lazyError: _ctx.lazyError })
          ]),
          (_ctx.errorMessage)
            ? (_openBlock(), _createBlock(_component_w_alert, {
                key: 1,
                class: "mt-8",
                mode: _ctx.mode
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_w_alert_description, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["mode"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.$slots.actions)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "actions", {
                tryDiscard: _ctx.tryDiscard,
                trySubmit: _ctx.trySubmit
              })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_w_action_dialog, {
          modelValue: _ctx.showConfirmDiscard,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showConfirmDiscard) = $event)),
          title: "Discard Change?",
          description: "You have unsaved changes. Proceed to discard?"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_w_button, { onClick: _ctx.confirmDiscard }, {
              default: _withCtx(() => [
                _createTextVNode("Discard")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_w_button, {
              outlined: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showConfirmDiscard = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cancel")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ], 32))
}