export default {
  install(App: any) {
    App.config.globalProperties.$constant = {
      tooltip: {
        connection: {
          key: "connection_tooltip",
          default: "Connections allow you to pre-register your different software to integrate them into your flows.",
        },
        copyKey: {
          key: "copy_key",
          default: "This is the connection key",
        },
        apiKey: {
          key: "apikey_tooltip",
          default:"The API Key allows you to give a connection key to your different collaborators so that they can interact with your flows."
        },
        typeChart: {
          key: "type_chart_tooltip",
          default: "Different types of visualization of your reporting",
        },
        report: {
          key: "report_tooltip",
          default: "Predetermined duration",
        },
        flowPage: {
          key: "flow_tooltip",
          default: "A flow is a succession of actions, also called steps, necessary to transfer data from one platform to another, to make a sequence of actions automatic, or both. ",
        },
        schedulerPage: {
          key: "scheduler_page",
          default: "The scheduler allows to planning the launch of the different flows to automate them. ",
        },
        documentTemplates: {
          key: "document_templates_tooltip",
          default: "The Document template allows you to create a dynamic document, such as invoice, and then integrate it into your flow (rendered in PDF format)",
        },
        formTemplate: {
          key: "form_template_tooltip",
          default: "The Form template allows you to create a template with fields to fill, and then integrate it into your flow  template allows you to create a dynamic mail and then integrate it into your flow (rendeed in HTML format)",
        },
        mailTemplate: {
          key: "mail_template_tooltip",
          default: "The Mail template allows you to create a dynamic mail and then integrate it into your flow (rendered in HTML format)",
        },
        environment: {
          key: "environment",
          default: "Allows the creation of environments within the same software in order to optimally organize data processing",
        },
        schedulerFlow:{
          key:"scheduler_flow_tooltip",
          default:"Choose the flow you want to schedule"
        },
        schedulerConnection:{
          key:"scheduler_connection_tooltip",
         default:"Choose the connection you want to use"
        },
        schedulerTimeout:{
          key:"scheduler_timeout_tooltip",
          default:"Choose the maximum amount of information processed per execution"
        },
        schedulerRecurrent:{
          key:"scheduler_recurrent_tooltip",
          default:"Choose the recurrence and frequency of the execution"
        },
        schedulerParameters:{
          key:"scheduler_parameters_tooltip",
          default:"The parameters allow to sending additional information to the chosen platform. The proposed parameters depend on the chosen platform."
        },
        schedulerHeaderMethodName:{
          key:"scheduler_method_name_tooltip",
          default:"Define how you will collect the data for your different flows"
        },
        schedulerHeaderTimeout:{
          key:"scheduler_timeout_header_tooltip",
          default:"Defines the amount of information processed for each flow execution"
        },
        environmentIdTooltip:{
          key:"environmentid_tooltip",
          default:"This is the key to your environment"
        },
        button:{
          testConnection:{
            key:"test_connection_tooltip",
            default:"Allows you to test your connection before entering it into a Flow"
          }
        } 
      },
    }
  },
}
