
import { defineComponent, PropType } from "vue"

import WInput from "./WInput.vue"
import { WIconButton } from "@/components/buttons"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    id: String,
    label: String,
    type: {
      type: String as PropType<"email" | "number" | "password" | "search" | "tel" | "text" | "url">,
      default: "text",
    },
    modelValue: String,
    autocomplete: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
    plain: Boolean,
    warningMessage: String,
    errorMessage: String,
    hint: null as null | Translatable,
    disabled: Boolean,
    optional: Boolean,
  },
  data() {
    return {
      inputType: this.type,
    }
  },
  methods: {
    switchPasswordVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password"
    },
  },
  components: { WInput, WIconButton },
})
