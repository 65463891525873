
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    strong: Boolean,
    to: {
      type: String,
      required: true,
    },
  },
})
