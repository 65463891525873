export default class Dictionary {
  private constructor(
    public readonly language: string,
    public readonly dataSourceId: string,
    public readonly groupId: string,
    public readonly listenerId: string,
    public readonly platformId: string,
    public readonly platformCoreId: string,
    public readonly webhookSourceId: string,
    public readonly translations: Record<string, string>
  ) {}

  static decode(data: any): Dictionary {
    const translations = data.translations.reduce((obj: any, item: any) => {
      obj[item.key] = item.value
      return obj
    }, {})
    return new Dictionary(
      data.lang,
      data.iddata_source,
      data.idgroup,
      data.idlistener,
      data.idplatform,
      data.idplatform_core,
      data.idwebhook_source,
      data.translations = translations
    )
  }
}