
import { defineComponent } from "vue"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue"
import WIconButton from "../buttons/WIconButton.vue"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: Boolean,
    padding: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    title: String,
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    needsClose() {
      if (!this.persistent) {
        this.$emit("update:modelValue", false)
      }
    },
  },

  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    WIconButton,
  },
})
