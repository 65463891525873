
import { defineComponent, PropType } from "vue"
import { WIcon } from "@/components/misc"

export default defineComponent({
  props: {
    id: String,
    label: String,
    item: {
      type: null as unknown as PropType<any | null>,
      required: true,
    },
    returnKey: {
      type: String,
      default: "id",
    },
    itemKey: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "text",
    },
  },
  components: {
    WIcon,
  },
})
