
import { defineComponent } from "vue"
import AppSidebar from "./AppSidebar.vue"
import AppFooter from "./AppFooter.vue"
import { WSpinner } from "@/components/misc"

import { getPlatforms, getOrphanListeners } from "@/platform"
import { getEnvironments } from "@/environment"

export default defineComponent({
  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      this.loadPlatforms()
      this.loadOrphanListeners()
      this.loadEnvironments()
    },
    async loadPlatforms() {
      const platforms = await getPlatforms()
      this.$store.commit("setPlatforms", platforms)
    },
    async loadOrphanListeners() {
      const listeners = await getOrphanListeners()
      this.$store.commit("setOrphanListeners", listeners)
    },
    async loadEnvironments() {
      const environments = await getEnvironments()
      this.$store.commit("setEnvironments", environments)
    },
  },

  components: {
    AppSidebar,
    AppFooter,
    WSpinner,
  },
})
