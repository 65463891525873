import Schema from "@/flow/models/Schema"
import Method from "./Method"
import { toRecord } from "@/utilities/functions"

export default class Listener {
  private constructor(
    public readonly id: string,
    public readonly key: string,
    public readonly schemas: Record<string, Schema>,
    public readonly methods: Record<string, Method>
  ) {}

  static decode(data: any): Listener {
    const methods: Record<string, Method> = {}
    if (data.methods) {
      const _methods = JSON.parse(data.methods)
      Object.keys(_methods).forEach((methodName: any) => {
        if (_methods[methodName].display_name) {
          methods[methodName] = Method.fromResponse(
            _methods[methodName],
            methodName,
            data.idplatform ?? null,
            data.idlistener,
            data.key
          )
        }
      })
    }
    return new Listener(
      data.idlistener,
      data.key,
      toRecord(
        data.schemas.map((each: any) => Schema.decode(each, data.idplatform ?? null)),
        "logicalName"
      ),
      methods
    )
  }
}

// import Method from "./Method"

// export default class Listener {
//   private _id: string | null
//   get id(): string | null {
//     return this._id
//   }
//   readonly key: string

//   displayName: string
//   description: string
//   queueName: string
//   // entity: string // Same as a methods.entities.description.default
//   platformId: string | null

//   status: boolean
//   methods: Record<string, Method>

//   private constructor(params: New<Listener>) {
//     this._id = null
//     this.key = params.key!
//     this.displayName = params.displayName!
//     this.description = params.description!
//     this.queueName = params.queueName!
//     this.platformId = params.platformId ?? null
//     this.status = params.status ?? true
//     this.methods = params.methods!
//   }

//   static decode(data: any): Listener {
//     const methods: Record<string, Method> = {}
//     if (data.methods) {
//       const _methods = JSON.parse(data.methods)
//       Object.keys(_methods).forEach((methodName: any) => {
//         if (_methods[methodName].display_name) {
//           methods[methodName] = Method.fromResponse(
//             _methods[methodName],
//             methodName,
//             data.idlistener,
//             data.key
//           )
//         }
//       })
//     }
//     const listener = new Listener({
//       key: data.key,
//       displayName: data.displayname,
//       description: data.description,
//       queueName: data.queuename,
//       platformId: data.idplatform,
//       status: data.status == 1,
//       methods: methods,
//     })
//     listener._id = data.idlistener
//     return listener
//   }
// }
