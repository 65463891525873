
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        'material-icons-round': !this.outlined,
        'material-icons-outlined': this.outlined,
        'text-[16px]': this.small,
        'text-[12px]': this.xSmall
      }
    },
  },
})
