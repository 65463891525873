import { renderSlot as _renderSlot, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-10 flex" }
const _hoisted_2 = ["id", "type", "disabled", "value", "autocomplete", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_input_wrapper = _resolveComponent("w-input-wrapper")!

  return (_openBlock(), _createBlock(_component_w_input_wrapper, {
    id: _ctx._id,
    label: _ctx.label,
    disabled: _ctx.disabled,
    optional: _ctx.optional,
    plain: _ctx.plain,
    warningMessage: _ctx.warningMessage,
    errorMessage: _ctx.errorMessage,
    hint: _ctx.hint
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "leading", {
          focus: () => _ctx.$refs.theInput.focus()
        }),
        _createElementVNode("input", {
          class: _normalizeClass(["grow focus:border-none focus:ring-0 border-none bg-transparent", [_ctx.type === 'password' ? 'placeholder:text-gray-800' : 'placeholder:text-gray-400']]),
          ref: "theInput",
          id: _ctx._id,
          type: _ctx.type,
          disabled: _ctx.disabled,
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          autocomplete: _ctx.autocomplete ? 'on' : 'off',
          placeholder: _ctx.placeholder,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus'))),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur'))),
          onKeydown: [
            _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('esc', () => _ctx.$refs.theInput.blur())), ["prevent"]), ["esc"])),
            _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('enter', () => _ctx.$refs.theInput.blur())), ["prevent"]), ["enter"])),
            _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('up')), ["prevent"]), ["up"])),
            _cache[6] || (_cache[6] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('down')), ["prevent"]), ["down"]))
          ]
        }, null, 42, _hoisted_2),
        _renderSlot(_ctx.$slots, "trailing", {
          focus: () => _ctx.$refs.theInput.focus()
        })
      ])
    ]),
    _: 3
  }, 8, ["id", "label", "disabled", "optional", "plain", "warningMessage", "errorMessage", "hint"]))
}