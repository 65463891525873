
import WHintIcon from "@/components/tooltip/WHintIcon.vue"
import { defineComponent, PropType } from "vue"

export default defineComponent({
  components: { WHintIcon },
  emits: ["update:modelValue"],
  props: {
    id: String,
    label: {
      type: String,
      default: "Select Range",
    },
    zIndex: String,
    placeholder: String,
    hint: null as null | Translatable,
    errorMessage: String,
    placement: {
      type: String,
      default: "right",
    },
    plain: Boolean,
    loading: Boolean,
    disabled: Boolean,
    optional: Boolean,
    modelValue: Object,
    minDate: Date,
  },
  watch: {
    range: {
      handler() {
        this.$emit("update:modelValue", this.range)
      },
      deep: true,
    },
  },
  beforeMount() {
    this.range.start = this.modelValue!.start
    this.range.end = this.modelValue!.end
  },
  data() {
    return {
      search: null as string | null,
      rect: undefined as any,
      selectedItems: [] as Array<any>,

      range: {
        start: {} as Date,
        end: {} as Date,
      } as { start: Date; end: Date },

      masks: {
        input: "YYYY-MM-DD h:mm A" as string,
      } as { input: string },
    }
  },
})
