import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!

  return (_openBlock(), _createBlock(_component_Line, {
    "chart-data": _ctx.data,
    "chart-options": {
      responsive: true,
      maintainAspectRatio: false,
    }
  }, null, 8, ["chart-data"]))
}