import store from "@/store"

export default class ConnectionTag {
  private constructor(
    public readonly connectionId: string,
    public readonly connectionName: string,
    // public readonly tagIds: string[]
    public readonly tagId: string | null
  ) {}

  static fromConnectionResponse(data: any) {
    return new ConnectionTag(
      data.idconnection,
      data.name,
      // data.connection_environments.map((each: any) => each.idtag)
      data.connection_environments.length > 0 ? data.connection_environments[0].idtag : null
    )
  }

  static fromConnectionEnvironmentResponse(data: any) {
    return new ConnectionTag(
      data.connection.idconnection,
      data.connection.name,
      data.idtag
    )
  }

  oDataJson(platformId: string): any {
    return {
      name: this.connectionName,
      idplatform: `platform(${platformId})`,
      connection_environments: [
        {
          idconnection: `connection(${this.connectionId})`,
          idenvironment: `environment(${store.getters.defaultEnvironmentId})`,
        },
      ],
    }
  }
}
