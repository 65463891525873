import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid min-h-screen mx-4 place-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between mb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_dialog_title = _resolveComponent("dialog-title")!
  const _component_w_icon_button = _resolveComponent("w-icon-button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    appear: "",
    show: _ctx.modelValue,
    as: "template"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "fixed inset-0 z-[10001] overflow-y-auto"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TransitionChild, {
              as: "template",
              enter: "duration-300 ease-out",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "duration-200 ease-in",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DialogOverlay, {
                  class: "fixed inset-0 bg-gray-300/60",
                  onClick: _withModifiers(_ctx.needsClose, ["stop"])
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_TransitionChild, {
              as: "template",
              enter: "duration-300 ease-out",
              "enter-from": "opacity-0 scale-95",
              "enter-to": "opacity-100 scale-100",
              leave: "duration-200 ease-in",
              "leave-from": "opacity-100 scale-100",
              "leave-to": "opacity-0 scale-95"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass([
              'transform bg-white shadow-xl rounded-xl min-w-[300px]',
              _ctx.padding ? 'p-6 ' : '',
            ])
                }, [
                  (_ctx.title)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_dialog_title, { class: "text-xl" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.title), 1)
                          ]),
                          _: 1
                        }),
                        (_ctx.showCloseButton)
                          ? (_openBlock(), _createBlock(_component_w_icon_button, {
                              key: 0,
                              small: "",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', false)))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("close")
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "default")
                ], 2)
              ]),
              _: 3
            })
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["show"]))
}