import { required, helpers } from "@vuelidate/validators"
import Encoder from "@/utilities/Encoder"

export default class Environment {
  private _id: string | null
  get id(): string | null {
    return this._id
  }

  name: string
  status: boolean
  
  private _isDefault: boolean
  get isDefault(): boolean {
    return this._isDefault
  }

  constructor(params: New<Environment>) {
    this._id = null
    this.name = params.name!
    this.status = params.status ?? true
    this._isDefault = false
  }

  static fromResponse(data: any): Environment {
    const environment = new Environment({
      name: data.name,
      status: data.status == 1,
    })
    environment._id = data.idenvironment
    environment._isDefault = data.is_default == 1
    return environment
  }

  encode(): any {
    const encoder = new Encoder(this)
    return encoder.encodeProps("name", "status").json()
  }

  private _o: {
    status: boolean
    name: string
  } | null = null



  static validations(): any {
    return {
      name: {
        required: helpers.withMessage("Name is required", required),
      },
    }
  }
}
