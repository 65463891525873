import { unauthedClient, apiError } from "./_client"
import Dictionary from "@/models/translation/Dictionary"

async function getAppTranslation(lang: string) {
  try {
    const query = `dictionary?$filter=lang eq '${lang}' and idplatform eq null&$expand=translations($select=key,value)&$select=lang`
    const response = await unauthedClient.get(query)
    const dictionary = Dictionary.decode(response.data.records[0])
    return dictionary.translations
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getTranslation(lang:string) {
  try {
    return {
      firstname: "First Name",
      lastname: "Last Name",
      confirmpassword: "Confirm password",
      email: "Email",
      password: "Password",
      next: "Next",
      cancel: "Cancel",
      save: "Save",
      previous: "Previous",
      signup: "Sign Up",
      login: "Login",
      logout: "Logout",
      submit: "Submit",
      discard: "Discard",
      country: "Country",
      company: "Company",
      contactemail: "Contact email",
      create: "Create",
      createconnection: "Create Connection",
      createapikey: "Create API Key",
      createflow: "Create Flow",
      name: "Name",
      platform: "Platform",
      exampleinput: "Example Input",
      connection: "Connection",
      profile: "Profile",
      search: "Search",
      status: "Status",
      apikey: "API Key",
      description: "Description",
      active: "Active",
      key: "Key",
      flow: "Flow",
      flows: "Flows",
      priority: "Priority",
      flowid: "Flow Id",
      document: "Document",
      documenttemplates: "Document Templates",
      createtemplate: "Create template",
      form: "Form",
      formtemplates: "Form Templates",
      emailtemplate: "Email Templates",
      dashboard: "Dashboard",
      templates: "Templates",
    }
  } catch (error) {
    throw apiError(error, {})
  }
}

export { getTranslation, getAppTranslation }
