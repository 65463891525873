
import { defineComponent } from "vue"
import WHintIcon from "../tooltip/WHintIcon.vue"

export default defineComponent({
  components: { WHintIcon },
  emits: ["update:modelValue"],
  props: {
    modelValue: Boolean,
    id: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
     hint: null as null | Translatable,
    precedingLabel: Boolean,
  },
  computed: {
    _id() {
      return this.id ?? this.label.toLowerCase().replace(/ /g, "")
    },
  },
})
