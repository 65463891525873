import { createRouter, createWebHistory } from "vue-router"
import { isAuthed } from "@/auth"

import HomePage from "@/components/app/HomePage.vue"
import Dashboard from "@/dashboard/components/DashboardPage.vue"
import i18n, { translateTo } from "@/localize/i18n"
import { getUserDefault } from "@/models/translation/UsergetDefault"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:lang?",
      redirect: `/${i18n.global.locale}`,
      component: {
        template: "<router-link></router-link>",
      },
    },
    {
      path: "/",
      name: "home",
      component: HomePage,
      meta: {
        requiresAuth: true,
      },
      redirect: "/dashboard",
      children: [
        {
          path: "/:lang?/dashboard",
          name: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/flow",
          name: "flowlist",
          component: () => import("@/flow/components/FlowPage.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/flow/new",
          name: "newflow",
          component: () => import("@/flow/components/FlowEditorPage.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/flow/:flowId",
          name: "floweditor",
          component: () => import("@/flow/components/FlowEditorPage.vue"),
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/connection",
          name: "connection",
          component: () => import("@/connection/components/ConnectionPage.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/environment",
          name: "environment",
          component: () => import("@/environment/EnvironmentPage.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/apikey",
          name: "key",
          component: () => import("@/apikey/ApiKeyPage.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/scheduler",
          name: "scheduler",
          component: () => import("@/scheduler/SchedulerPage.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/tag",
          name: "tag",
          component: () => import("@/tag/TagPage.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/document-templates",
          name: "documenttemplates",
          component: () => import("@/components/pages/home/templates/DocumentTemplates.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/email-templates",
          name: "email",
          component: () => import("@/components/pages/home/templates/EmailTemplates.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/:lang?/form-templates",
          name: "form",
          component: () => import("@/components/pages/home/templates/FormTemplates.vue"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/:lang?/auth",
      component: () => import("@/auth/components/AuthPage.vue"),
      redirect: "/login",
      children: [
        {
          path: "/:lang?/login",
          name: "login",
          component: () => import("@/auth/components/LoginCard.vue"),
          meta: {
            requiresUnAuth: true,
          },
        },
        {
          path: "/:lang?/register",
          name: "register",
          component: () => import("@/auth/components/RegistrationCard.vue"),
          meta: {
            requiresUnAuth: true,
          },
        },
        {
          path: "/:lang?/recoverpassword",
          name: "passwordrecovery",
          component: () => import("@/auth/components/PasswordRecoveryCard.vue"),
          meta: {
            requiresUnAuth: true,
          },
        },
      ],
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth && !(await isAuthed())) {
    next({ name: "login" })
    return
  }
  if (to.meta.requiresUnAuth && (await isAuthed())) {
    next({ name: "home" })
    return
  }
  next()
})

const validLangs = ["en", "fr"]
router.beforeEach(async (to, from, next) => {
  let getlang = to.params.lang as any
  if (!getlang || getlang.length === 0 || !validLangs.includes(getlang)) {
    getlang = getUserDefault()
    router.push({ name: to.name!, params: { lang: getlang } })
  }
  translateTo(getlang as any)
  next()
})

export default router
