
import { defineComponent } from "vue"
import WHintIcon from "../tooltip/WHintIcon.vue"

export default defineComponent({
  props: {
    id: String,
    label: String,
    warningMessage: String,
    errorMessage: String,
    hint: null as null | Translatable,
    plain: Boolean,
    optional: Boolean,
    disabled: Boolean,
  },
  computed: {
    classes() {
      return {
        "bg-gray-100": this.disabled,
        "rounded-md border border-gray-300 focus-within:border-primary-900 focus-within:ring-primary-900 focus-within:ring-1":
          !this.plain,
        "border-none focus-within:ring-0 focus-within:border-none focus-within:border-none": this.plain,
      }
    },
  },
  components: { WHintIcon },
})
