export default class DataPoint {
  private constructor(
    public readonly periodStart: Date,
    public readonly flowUsage: number,
    public readonly invoicing: number,
    public readonly activeTenant: number | null
  ) {}

  static fromResponse(data: any): DataPoint {
    return new DataPoint(
      // new Date("YYYY-MM-DD HH:MM:SS"),
      new Date(data.period_start),
      data.flow_usage,
      data.invoicing,
      data.active_tenant
    )
  }
}
