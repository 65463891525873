function toRecord<T>(array: readonly T[], key: string): Record<string, T> {
  return array.reduce((record, each: any) => {
    record[each[key]] = each
    return record
  }, {} as Record<string, T>)
}

async function toBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () =>
      resolve((reader.result as string).replace("data:", "").replace(/^.+,/, ""))
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}

function isJsonObject(str: string): boolean {
  try {
    const json = JSON.parse(str)
    return json instanceof Object || json instanceof Array
  } catch {
    return false
  }
}

export { toRecord, toBase64, isJsonObject }
