
import { defineComponent } from "vue"
import SettingsPage from "@/settings/SettingsPage.vue"
import { WDialog } from "@/components/modals"
import { WIcon } from "@/components/misc"
import { WIconButton } from "@/components/buttons"

export default defineComponent({
  data() {
    return {
      showDialog: false,
      internalGroups: [
        {
          name: "dashboard",
          items: [
            {
              icon: "leaderboard",
              title: "Dashboard",
              to: "/dashboard",
            },
          ],
        },
        {
          name: "quick",
          title: "QUICK",
          items: [
            {
              icon: "dns",
              title: "Connection",
              to: "/connection",
            },
            {
              icon: "sell",
              title: "Environment",
              to: "/environment",
            },
            {
              icon: "vpn_key",
              title: "API Key",
              to: "/apikey",
            },
            {
              icon: "event_repeat",
              title: "Scheduler",
              to: "/scheduler",
            },
            {
              icon: "account_tree",
              title: "Flow",
              to: "/flow",
            },
          ],
        },
        {
          name: "templates",
          title: "TEMPLATES",
          items: [
            {
              icon: "description",
              title: "Document",
              to: "/document-templates",
            },
            {
              icon: "mail",
              title: "Email",
              to: "/email-templates",
            },
            {
              icon: "assignment",
              title: "Form",
              to: "/form-templates",
            },
          ],
        },
      ],
    }
  },
  computed: {
    isCompact() {
      return this.$store.getters.isCompactSideBar
    },
  },
  components: {
    WDialog,
    SettingsPage,
    WIcon,
    WIconButton,
  },
})
