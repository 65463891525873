import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "min-h-10 flex flex-wrap w-96 grow" }
const _hoisted_3 = {
  key: 1,
  class: "h-10 aspect-square grid place-items-center"
}
const _hoisted_4 = {
  key: 0,
  class: "cursor-default select-none py-2 px-4 text-gray-700"
}
const _hoisted_5 = { class: "block truncate" }
const _hoisted_6 = {
  key: 0,
  class: "block truncate text-sm text-gray-400"
}
const _hoisted_7 = {
  key: 0,
  class: "absolute inset-y-0 left-0 items-center w-10 material-icons-round text-primary-900 place-items-center justify-around flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_chip = _resolveComponent("w-chip")!
  const _component_combobox_input = _resolveComponent("combobox-input")!
  const _component_w_icon = _resolveComponent("w-icon")!
  const _component_combobox_button = _resolveComponent("combobox-button")!
  const _component_w_spinner = _resolveComponent("w-spinner")!
  const _component_w_input_wrapper = _resolveComponent("w-input-wrapper")!
  const _component_combobox_option = _resolveComponent("combobox-option")!
  const _component_combobox_options = _resolveComponent("combobox-options")!
  const _component_transition_root = _resolveComponent("transition-root")!
  const _component_combobox = _resolveComponent("combobox")!

  return (_openBlock(), _createBlock(_component_combobox, {
    as: "div",
    class: _normalizeClass({
      flex: _ctx.optionsPosition !== 'bottom',
      'flex-row-reverse': _ctx.optionsPosition === 'left',
    }),
    modelValue: _ctx.selectedItems,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItems) = $event)),
    multiple: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_w_input_wrapper, {
        ref: "combobox",
        id: _ctx._id,
        label: _ctx.label,
        disabled: _ctx.loading || _ctx.disabled,
        optional: _ctx.optional,
        plain: _ctx.plain,
        errorMessage: _ctx.errorMessage,
        hint: _ctx.hint,
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedItems, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item[_ctx.returnKey!],
                  class: "pl-2 pt-2"
                }, [
                  _createVNode(_component_w_chip, {
                    item: item,
                    itemText: _ctx.itemText,
                    itemKey: _ctx.returnKey,
                    onOnRemove: _ctx.removeFromItems
                  }, null, 8, ["item", "itemText", "itemKey", "onOnRemove"])
                ]))
              }), 128)),
              _createVNode(_component_combobox_input, {
                class: "focus:border-none focus:ring-0 border-none bg-transparent placeholder:text-gray-400 max-w-[100%] min-w-[64px] w-[100%] flex-1 pl-2",
                id: _ctx._id,
                disabled: _ctx.loading || _ctx.disabled,
                placeholder: _ctx.loading ? null : _ctx.placeholder,
                displayValue: (item) => item[_ctx.itemText],
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search = $event.target.value))
              }, null, 8, ["id", "disabled", "placeholder", "displayValue"])
            ]),
            _createElementVNode("div", null, [
              (!_ctx.plain && !_ctx.loading)
                ? (_openBlock(), _createBlock(_component_combobox_button, {
                    key: 0,
                    disabled: _ctx.disabled,
                    class: "h-10 aspect-square text-gray-600 enabled:hover:text-gray-900"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_w_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("unfold_more")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled"]))
                : _createCommentVNode("", true),
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_w_spinner, { small: "" })
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["id", "label", "disabled", "optional", "plain", "errorMessage", "hint"]),
      _createVNode(_component_transition_root, {
        class: _normalizeClass(["flex", {
        'flex-row-reverse': _ctx.optionsPosition === 'left',
        'items-center': _ctx.bottomGap! < 400 && _ctx.bottomGap! > 250,
        'items-end': _ctx.bottomGap! < 250,
      }]),
        onAfterLeave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.search = null))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_combobox_options, {
            class: _normalizeClass([
          _ctx.fixed ? 'fixed w-96' : 'absolute w-full',
          'fixed max-h-96 w-full py-1 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50',
          _ctx.optionsPosition === 'bottom' ? 'mt-2' : 'mx-2' + (_ctx.label ? ' mt-6' : ''),
        ])
          }, {
            default: _withCtx(() => [
              (_ctx.searchedItem.length === 0 && _ctx.search !== '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Nothing found. "))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchedItem, (item) => {
                return (_openBlock(), _createBlock(_component_combobox_option, {
                  as: "template",
                  key: item[_ctx.itemKey ?? _ctx.returnKey ?? 'key'],
                  value: item
                }, {
                  default: _withCtx(({ selected, active }) => [
                    _createElementVNode("li", {
                      class: _normalizeClass(["relative py-2 pl-10 pr-2 cursor-pointer select-none", {
              'bg-gray-200': active,
            }])
                    }, [
                      _renderSlot(_ctx.$slots, "item", {
                        item: item,
                        selected: selected,
                        active: active
                      }, () => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(item[_ctx.itemText]), 1),
                        (_ctx.itemSubText && item[_ctx.itemSubText])
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item[_ctx.itemSubText]) + " " + _toDisplayString(selected), 1))
                          : _createCommentVNode("", true)
                      ]),
                      selected
                        ? (_openBlock(), _createElementBlock("i", _hoisted_7, " check "))
                        : _createCommentVNode("", true)
                    ], 2)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 3
          }, 8, ["class"])
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 8, ["class", "modelValue"]))
}