import Environment from "../models/Environment"

export default {
  state: {
    defaultId: undefined as string | undefined,
    data: undefined as Environment[] | undefined
  },
  getters: {
    isEnvironmentLoaded(state: any) {
      return state.data != undefined
    },
    defaultEnvironmentId(state: any): string {
      return state.defaultId!
    },
    environments(state: any): Environment[] {
      return state.data!
    }
  },
  mutations: {
    setEnvironments(state: any, value: Environment[]) {
      state.data = value
      state.defaultId = value.find(each => each.isDefault)!.id
    }
  }
}