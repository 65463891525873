
import { defineComponent, PropType } from "vue"
import WSpinner from "../misc/WSpinner.vue"
import { WAlert, WAlertDescription } from "../alert"
import { WActionDialog } from "@/components/modals"
import { WButton } from "@/components/buttons"
import WSkeleton from "../skeleton"

export default defineComponent({
  emits: ["submit", "discard"],
  props: {
    title: String,
    skeletonLoading: Boolean,
    loading: Boolean,
    disabled: Boolean,
    errorMessage: String,
    validator: Object,
    canDiscard: Function as PropType<() => boolean>,
    mode: {
      type: String,
      default: "error",
    },
  },
  data() {
    return {
      submitAttempted: false,
      showConfirmDiscard: false,
    }
  },
  methods: {
    tryDiscard() {
      const canDiscard = this.canDiscard?.() ?? true
      if (!canDiscard) {
        this.showConfirmDiscard = true
        return
      }
      this.$emit("discard")
    },
    confirmDiscard() {
      this.showConfirmDiscard = false
      this.$emit("discard")
    },
    trySubmit() {
      this.submitAttempted = true
      const validator = this.validator as any
      validator?.$validate()
      if (!validator?.$invalid) {
        this.$emit("submit")
      }
    },
    lazyError(field: any) {
      return !this.submitAttempted || !field.$error ? null : field.$errors[0].$message
    },
  },
  components: { WSpinner, WAlert, WAlertDescription, WSkeleton, WActionDialog, WButton },
})
