
import { defineComponent, PropType } from "vue"
import { Dropdown } from "floating-vue"

import { WIcon } from "@/components/misc"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    label: String,
    optional: Boolean,
    disabled: Boolean,
    modelValue: {
      type: null as unknown as PropType<string>,
      require: true,
    },
    options: {
      type: Array,
      required: true,
    },
    optionDisplay: [String, Function],
    returnKey: String,
    errorMessage: String,
  },
  computed: {
    selectedOption(): any {
      if (!this.modelValue) {
        return null
      }
      return this.returnKey
        ? this.options.find((each: any) => each[this.returnKey!] === this.modelValue)
        : this.modelValue
    },
  },
  methods: {
    getDisplay(option: any) {
      if (!this.optionDisplay || !option) {
        return option
      }
      if (typeof this.optionDisplay === "string") {
        return option[this.optionDisplay] ?? option
      } else {
        return this.optionDisplay(option)
      }
    },
    updateSelected(item: any) {
      const value = this.returnKey ? item?.[this.returnKey] ?? null : item
      this.$emit("update:modelValue", value)
    },
  },
  components: {
    Dropdown,
    WIcon,
  },
})
