
import { defineComponent, PropType } from "vue"

import { Listbox, ListboxButton, ListboxOptions, ListboxOption, TransitionRoot } from "@headlessui/vue"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: null as unknown as PropType<any | null>,
      required: true,
    },
    returnKey: {
      type: Boolean,
      required: false,
    },
    id: String,
    label: String,
    items: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: "key",
    },
    itemText: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
    _modelValue() {
      return !this.returnKey ? this.modelValue : this.items.find((each) => each[this.itemKey] === this.modelValue)!
    },
    classes() {
      return {
        "bg-gray-100": this.disabled,
      }
    }
  },
  methods: {
    modelValueUpdated(item: any) {
      const value = !this.returnKey ? item : item[this.itemKey]
      this.$emit("update:modelValue", value)
    },
  },

  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    TransitionRoot,
  },
})
