import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "value", "rows", "placeholder", "disabled", "grows", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_input_wrapper = _resolveComponent("w-input-wrapper")!

  return (_openBlock(), _createBlock(_component_w_input_wrapper, {
    id: _ctx._id,
    label: _ctx.label,
    disabled: _ctx.disabled,
    optional: _ctx.optional,
    warningMessage: _ctx.warningMessage,
    errorMessage: _ctx.errorMessage,
    hint: _ctx.hint
  }, {
    default: _withCtx(() => [
      _createElementVNode("textarea", {
        ref: "textarea",
        class: "w-full resize-none focus:border-none focus:ring-0 border-none bg-transparent placeholder:text-gray-400",
        id: _ctx._id,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resizeHeight && _ctx.resizeHeight(...args))),
        rows: _ctx.rows,
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        grows: _ctx.grows,
        readonly: _ctx.readonly
      }, null, 40, _hoisted_1)
    ]),
    _: 1
  }, 8, ["id", "label", "disabled", "optional", "warningMessage", "errorMessage", "hint"]))
}