
import { defineComponent, PropType } from "vue"

import WInputWrapper from "../WInputWrapper.vue"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    id: String,
    label: String,
    zIndex: String,
    placeholder: String,
    hint: String,
    errorMessage: String,
    placement: {
      type: String,
      default: "bottom",
    },
    plain: Boolean,
    loading: Boolean,
    disabled: Boolean,
    optional: Boolean,
    modelValue: Date,
  },
  watch: {
    date: {
      handler() {
        this.$emit("update:modelValue", this.date)
      },
    },
  },
  data() {
    return {
      date: new Date() as Date,
    }
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
  },
  methods: {},
  beforeMount() {
    if (this.modelValue) this.date = this.modelValue as Date
  },
  components: {
    WInputWrapper,
  },
})
