<template>
  <Menu as="div" class="relative w-fit inline-block">
    <MenuButton
      class="focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-700 disabled:opacity-50"
      :class="classes"
      :disabled="disabled"
    >
      <w-icon v-if="icon"><slot /></w-icon>
      <slot v-else />
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        :class="positionClasses"
        class=" h-fit z-10 absolute mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg min-w-[200px] ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div v-for="(group, groupIndex) in items" :key="groupIndex" class="px-1 py-1">
          <MenuItem v-for="item in group" :key="item" v-slot="{ active }">
            <button
              type="button"
              :class="[
                active ? 'bg-primary-50 text-primary-900' : 'text-black',
                'flex items-center rounded-md w-full px-2 py-2 text-sm',
              ]"
              @click="$emit('select', item.id)"
            >
              <w-icon v-if="item.icon" small class="mr-2">{{ item.icon }}</w-icon>
              {{ item.text }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import WIcon from "../misc/WIcon.vue"

export default {
  emits: ["select"],
  props: {
    icon: Boolean,
    items: {
      type: Array,
      required: true,
    },
    left: Boolean,
    right: Boolean,
    top: Boolean,
    bottom: Boolean,
    disabled: Boolean,
    gray: Boolean,
    shy: Boolean,
  },
  computed: {
    isGray() {
      return !this.shy && this.gray
    },
    isShy() {
      return this.shy || !this.gray
    },
    classes() {
      return {
        "text-gray-800 bg-gray-200 enabled:hover:text-gray-900 enabled:hover:bg-gray-300": this.isGray,
        "text-gray-600 enabled:hover:text-gray-900": this.isShy,
        "h-10 aspect-square rounded-full": this.icon,
        "py-2 px-4 font-medium rounded-md": !this.icon,
      }
    },
    topLeft() {
      return this.top && this.left
    },
    topRight(){
      return this.top && this.right
    },
    bottomLeft(){
      return this.bottom && this.left
    },
    bottomRight(){
      return this.bottom && this.right
    },
    positionClasses() {
      return {
        "translate-[-10px, -50%] absolute bottom-auto right-full top-0": this.left,
        "translate-[-10px, -50%] absolute bottom-auto left-full top-0": this.right,
        "bottom-full": this.top,
        "top-full": this.bottom,
        "right-full -top-20":this.topLeft,
        "-top-20 left-full":this.topRight,
        "-bottom-20 right-full top-1/2":this.bottomLeft,
        "-bottom-20 left-full top-1/2":this.bottomRight
      }
    },
  },

  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    WIcon,
  },
}
</script>
