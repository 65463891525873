import store from "@/store"
import Encoder from "@/utilities/Encoder"

export default class Connection {
  private _id: string | null = null
  get id(): string | null {
    return this._id
  }

  platformId: string
  get platformName(): string {
    return store.getters.platformById(this.platformId).name
  }
  get platformLogo() {
    try {
      return require(`@/assets/logos/${this.platformName}.png`)
    } catch (_) {
      return require(`@/assets/logos/wiresk.png`)
    }
  }

  get testable(): boolean {
    return store.getters.platformById(this.platformId).testable
  }

  get connectionSchemas(): Record<string, ConnectionSchema> {
    return store.getters.platformById(this.platformId).connectionSchemas
  }

  name: string
  status: boolean
  url: string | null
  username: string | null
  password: string | null
  secret: string | null
  // passwordSet: boolean
  // secretSet: boolean
  clientId: string | null
  tenantId: string | null
  apiKey: string | null
  connectionId: string | null
  certificateName: string | null
  certificate: string | null
  jsonParameter: string | null
  connectionSchemaId: string | null

  // connectionEnvironments: ConnectionEnvironment[] | undefined

  get(field: string): string | null {
    switch (field) {
      case "url":
        return this.url
      case "username":
        return this.username
      case "password":
        return this.password
      case "secret":
        return this.secret
      case "client_id":
        return this.clientId
      case "tenant_id":
        return this.tenantId
      case "api_key":
        return this.apiKey
        case "certificate":
          return this.certificate
      case "certificate_name":
        return this.certificateName
      case "json_parameter":
        return this.jsonParameter
      default:
        return null
    }
  }

  set(field: string, value: string | null) {
    switch (field) {
      case "url":
        this.url = value
        break
      case "username":
        this.username = value
        break
      case "password":
        this.password = value
        break
      case "secret":
        this.secret = value
        break
      case "client_id":
        this.clientId = value
        break
      case "tenant_id":
        this.tenantId = value
        break
      case "api_key":
        this.apiKey = value
        break
      case "certificate":
        this.certificate = value
        break
      case "certificate_name":
        this.certificateName = value
        break
      case "json_parameter":
        this.jsonParameter = value
        break
      default:
        break
    }
  }

  constructor(params: New<Connection>) {
    this.platformId = params.platformId!
    this.name = params.name!
    this.status = params.status ?? true
    this.url = params.url ?? null
    this.username = params.username ?? null
    this.password = params.password ?? null
    this.secret = params.secret ?? null
    this.clientId = params.clientId ?? null
    this.tenantId = params.tenantId ?? null
    this.apiKey = params.apiKey ?? null
    this.connectionId = params.connectionId ?? null
    this.certificateName = params.certificateName ?? null
    this.certificate = params.certificate ?? null
    this.jsonParameter = params.jsonParameter ?? null
    this.connectionSchemaId = params.connectionSchemaId ?? null

    // this.connectionEnvironments = params.connectionEnvironments ?? []
  }

  static fromResponse(data: any): Connection {
    const connection = new Connection({
      platformId: data.idplatform,
      name: data.name,
      status: data.status == 1,
      url: data.url,
      username: data.username,
      password: '',
      secret: '',
      clientId: data.client_id,
      tenantId: data.tenant_id,
      apiKey: data.api_key,
      certificateName: data.certificate_name ?? null,
      connectionId: data.idconnection,
      certificate: data.certificate,
      jsonParameter: data.json_parameter,
      connectionSchemaId: data.idschema      
    })
    connection._id = data.idconnection
    // connection.connectionEnvironments = data.connection_environments?.map((each: any) => ConnectionEnvironment.fromResponse(each))

    connection._o = {
      name: data.name,
      status: data.status == 1,
      url: data.url,
      username: data.username,
      password: data.password_set,
      secret: data.secret_set,
      clientId: data.client_id,
      tenantId: data.tenant_id,
      apiKey: data.api_key,
      certificateName: data.certificate_name,
      certificate: data.certificate,
      jsonParameter: data.json_parameter,
      connectionSchemaId: data.idschema,
    }

    return connection
  }

  private _o: {
    readonly name: string
    readonly status: boolean
    readonly url: string | null
    readonly username: string | null
    readonly password: string | null
    readonly secret: string | null
    readonly clientId: string | null
    readonly tenantId: string | null
    readonly apiKey: string | null
    readonly certificateName: string | null
    readonly certificate: string | null
    readonly jsonParameter: string | null
    readonly connectionSchemaId: string | null
  } | null = null

  encode(): any {
    const encoder = new Encoder(this)
    encoder.encodeProps("name", "status")
    if (!this._id) {
      encoder.encodeValue(`platform(${this.platformId})`, "idplatform")
    }
    encoder
      .encodeProps("url", "username", "certificate", "secret", "password")
      .encodeProp("clientId", "client_id")
      .encodeProp("tenantId", "tenant_id")
      .encodeProp("connectionId", "idconnection")
      .encodeProp("jsonParameter", "json_parameter")
      .encodeProp("certificateName", "certificate_name")
      .encodeValue(`schema(${this.connectionSchemaId})`, "idschema")
      .encodeProp("apiKey", "api_key")
      // .encodeSecret("secret")
      // .encodeSecret("password")
    return encoder.json()
  }

  static decode(data: any): Connection {
    const connection = new Connection({
      platformId: data.platformId!,
      name: data.name!,
      status: data.status ?? true,
      url: data.url ?? null,
      username: data.username ?? null,
      password: data.password ?? '',
      secret: data.secret ?? '',
      clientId: data.clientId ?? null,
      tenantId: data.tenantId ?? null,
      apiKey: data.apiKey ?? null,
      connectionId: data.connectionId ?? null,
      certificateName: data.certificateName ?? null,
      certificate: data.certificate ?? null,
      jsonParameter: data.jsonParameter ?? null,
      connectionSchemaId: data.connectionSchemaId ?? null
    })
    connection._id = data.connectionId

    connection._o = {
      name: connection.name,
      status: connection.status,
      url: connection.url,
      username: connection.username,
      password: connection.password,
      secret: connection.secret,
      clientId: connection.clientId,
      tenantId: connection.tenantId,
      apiKey: connection.apiKey,
      certificateName: connection.certificateName,
      certificate: connection.certificate,
      jsonParameter: connection.jsonParameter,
      connectionSchemaId: connection.connectionSchemaId
    }

    return connection
  }
}

export class ConnectionSchema{
  private constructor(
    public readonly id: string,
    public readonly logicalName: string,
    public readonly displayName: {key:string, default:string},
    public readonly fields: Array<ConnectionSchemaField>
  ) {}

  static decode(data: any): ConnectionSchema {
    return new ConnectionSchema(
      data.idschema,
      data.name,
      !data.display ? undefined : JSON.parse(data.display),
      !data.content ? undefined : (JSON.parse(data.content)).fields.map((each :any)=> ConnectionSchemaField.decode(each)) ,
    )
  }
}

export class ConnectionSchemaField{
  private constructor(
    public readonly name: string,
    public readonly required: boolean,
    public readonly hidden: boolean,
    public readonly logicalName: string,
    public readonly displayName: string,
    public readonly description: Translatable
  ) {}

  static decode(data: any): ConnectionSchemaField {
    return new ConnectionSchemaField(
      data.name,
      data.required,
      data.hidden,
      data.display.key,
      data.display.default,
      data.description
    )
  }
}


export class ConnectionTestResponse{
  private constructor(
    public readonly tested: boolean,
    public readonly validConnection: boolean,
    public readonly message: string,
  ) {}

  static fromResponse(data: any): ConnectionTestResponse {
    const response = new ConnectionTestResponse(
      data.tested,
      data.valid_connection,
      data.message,
    );
    return response
  }
}
