import { ConnectionEnvironment } from "@/connection"

export default class Tag {
  private _id: string | null
  get id(): string | null {
    return this._id
  }

  name: string
  readonly platformId: string

  connectionEnvironments: ConnectionEnvironment[] | undefined

  constructor(params: New<Tag>) {
    this._id = null
    this.name = params.name!
    this.platformId = params.platformId!
    this.connectionEnvironments = params.connectionEnvironments ?? []
  }

  static fromResponse(data: any): Tag {
    const tag = new Tag({
      name: data.name,
      platformId: data.idplatform,
    })
    tag._id = data.idtag
    tag.connectionEnvironments = data.connection_environments?.map((each: any) =>
      ConnectionEnvironment.fromResponse(each)
    )
    return tag
  }

  oDataJson(): any {
    const json = {
      name: this.name,
      idplatform: `platform(${this.platformId})`,
    } as any
    if (this.connectionEnvironments && this.connectionEnvironments.length > 0) {
      json.connection_environments = this.connectionEnvironments.map((each) => each.oDataJson())
    }
    return json
  }
}
