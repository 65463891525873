import { Environment } from "@/environment"
import Encoder from "@/utilities/Encoder"

export default class FlowEnvironment {
  private _id: string | null
  get id(): string | null {
    return this._id
  }

  readonly environmentId: string
  readonly environment: Environment | null // related to getEnvironmentsByFlowId()

  constructor(environmentId: string, environment: Environment | null = null) {
    this._id = null
    this.environmentId = environmentId
    this.environment = environment // related to getEnvironmentsByFlowId()
  }

  static fromResponse(data: any): FlowEnvironment {
    const flowEnvironment = new FlowEnvironment(data.idenvironment, data.environment)
    flowEnvironment._id = data.idflow_environment
    flowEnvironment._o = {}
    return flowEnvironment
  }

  private _o: unknown = null

  encode(): any {
    const encoder = new Encoder(this)
    encoder.encodeRef("environment", "environmentId", "idenvironment")
    return encoder.json()
  }
}
