
import { defineComponent } from "vue"
import { Popover, PopoverButton, PopoverPanel, PopoverOverlay } from "@headlessui/vue"

export default defineComponent({
  props: {
    overlay: Boolean,
    zIndex:{
      default: "z-[1000]",
      type: String
    }
  },

  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    PopoverOverlay,
  },
})
