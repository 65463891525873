
import { defineComponent } from "vue"

import WBar from "./WBar.vue"

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  components: {
    WBar,
  },
})
