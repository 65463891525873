import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopoverOverlay = _resolveComponent("PopoverOverlay")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createBlock(_component_Popover, {
    class: _normalizeClass(["relative h-full", _ctx.zIndex])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopoverOverlay, {
        class: _normalizeClass(['fixed inset-0', _ctx.overlay ? 'bg-gray-300/30' : ''])
      }, null, 8, ["class"]),
      _createVNode(_component_PopoverButton, { class: "relative h-full w-full focus:outline-none" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      _createVNode(_component_PopoverPanel, { class: "absolute mt-3 p-4 -translate-x-4 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-50" }, {
        default: _withCtx(({ close }) => [
          _renderSlot(_ctx.$slots, "popover", { close: close })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["class"]))
}