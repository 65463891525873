export default class JsonFormsTemplates {
  constructor(private _templates: any) {}

  getSchemas(enums: string[]): any {
    return this._enumCallback(this._templates, enums)
  }

  private _enumCallback(template: any, enums: string[]): any {
    if (typeof template !== "object") {
      return template
    }
    if (Array.isArray(template)) {
      return template.map((each: any) => this._enumCallback(each, enums))
    }
    return Object.keys(template).reduce((build: any, key) => {
      if (key === "{{enum}}") {
        build["enum"] = enums
      } else {
        const value = template[key]
        build[key] = this._enumCallback(value, enums)
      }
      return build
    }, {})
  }
}
