import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "max-w-md min-w-[300px] space-y-6" }
const _hoisted_2 = { class: "space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_description = _resolveComponent("dialog-description")!
  const _component_w_alert_description = _resolveComponent("w-alert-description")!
  const _component_w_alert = _resolveComponent("w-alert")!
  const _component_w_dialog = _resolveComponent("w-dialog")!

  return (_openBlock(), _createBlock(_component_w_dialog, { title: _ctx.title }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.description)
          ? (_openBlock(), _createBlock(_component_dialog_description, {
              key: 0,
              class: "text-gray-600"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.description), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.errorMessage)
          ? (_openBlock(), _createBlock(_component_w_alert, {
              key: 1,
              mode: "error"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_w_alert_description, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ]),
    _: 3
  }, 8, ["title"]))
}