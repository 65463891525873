import { apiError, client } from "@/api/_client"

import Platform from "../models/Platform"
import Listener from "../models/Listener"

async function getPlatforms(): Promise<Platform[]> {
  try {
    const schemaExpand =
      "$expand=schemas($select=name,display,description,entity,idlistener,iddata_source)"
    const datasourceSchemaExpand =
      "$expand=schemas($select=name,display,description,idplatform_core,idlistener,iddata_source,content)"

    const listenerExpand = `listeners($select=key,idplatform,methods;${schemaExpand})`
    const datasourceExpand = `data_sources($select=displayname,idplatform;${datasourceSchemaExpand})`
    const query = `platform?$expand=schemas,tags,${listenerExpand},${datasourceExpand}`
    const response = await client.get(query)
    return response.data.records.map((each: any) => Platform.decode(each))
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getOrphanListeners(): Promise<Listener[]> {
  try {
    const schemaExpand =
      "$expand=schemas($select=name,display,description,entity,idlistener,iddata_source;$filter=name ne 'Advanced')"
    const query = `listener?$select=key&$filter=idplatform eq null&${schemaExpand}`
    const response = await client.get(query)
    return response.data.records.map((each: any) => Listener.decode(each))
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getListener(id: string) {
  try {
    return await client.get(`listen/${id}`)
  } catch (error) {
    throw apiError(error, {})
  }
}

export { getPlatforms, getOrphanListeners, getListener }
