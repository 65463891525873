
import { defineComponent } from "vue"

import { getSubTenant } from "@/api/tenant"
import { impersonate } from "@/auth"
import SubTenant from "@/models/SubTenant"

import { WActionDialog } from "@/components/modals"
import { WIcon } from "@/components/misc"
import { WButton } from "@/components/buttons"
import WSkeleton from "@/components/skeleton"

export default defineComponent({
  data() {
    return {
      tenants: undefined as undefined | SubTenant[],
      impersonateId: null as null | string,
      error: null as null | string,
      isLoading: false,
    }
  },
  mounted() {
    this.getTenants()
  },
  methods: {
    async getTenants() {
      this.tenants = await getSubTenant()
    },
    async impersonate() {
      try {
        this.isLoading = true
        this.error = null
        await impersonate({ tenantId: this.impersonateId! })
        location.href = location.origin
      } catch (error: any) {
        this.error = error.message
      } finally {
        this.isLoading = false
      }
    },
    cancelImpersonation() {
      this.impersonateId = null
      this.error = null
    },
  },
  components: {
    WActionDialog,
    WIcon,
    WButton,
    WSkeleton,
  },
})
