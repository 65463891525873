import { required, helpers, email, sameAs, minLength } from "@vuelidate/validators"
import messageCheck from "@/validator"

import Country from "../../models/user/Country"
import Encoder from "@/utilities/Encoder"

export default class RegistrationData {
  // First step
  readonly info: {
    firstName?: string
    lastName?: string
    phone?: string
    address: {
      line1?: string
      line2?: string
      line3?: string
      postcode?: string
      city?: string
      country?: Country
    }
  } = { address: {} }
  // Second step
  readonly credential: {
    tenantName?: string
    contactEmail?: string
    email?: string
    password?: string
    confirmPassword?: string
  } = {}

  private _o = null

  encode(): any {
    const encoder = new Encoder(this)
    encoder
      .encodeProp("info.address.line1", "address_line1")
      .encodeProp("info.address.line2", "address_line2")
      .encodeProp("info.address.line3", "address_line3")
      .encodeProp("info.address.postcode", "address_postcode")
      .encodeProp("info.address.city", "address_city")
      .encodeValue(`country(${this.info.address.country!.id})`, "idcountry")
      .encodeProp("info.firstName", "firstname")
      .encodeProp("info.lastName", "lastname")
      .encodeValue(this.info.address.country!.phonePrefix + this.info.phone!, "phone")
      .encodeProp("credential.tenantName", "tenant_name")
      .encodeProp("credential.contactEmail", "contact_email")
      .encodeProp("credential.email", "email")
      .encodeProp("credential.password", "password")
    return encoder.json()
  }

  static validations(password?: string) {
    return {
      info: {
        firstName: {
          required: helpers.withMessage("Please input your first name", required),
        },
        lastName: {
          required: helpers.withMessage("Please input your last name", required),
        },
        phone: {
          required: helpers.withMessage("Phone number is required", required),
        },
        address: {
          city: {
            required: helpers.withMessage("City is required", required),
          },
          country: {
            required: helpers.withMessage("Country is required", required),
          },
        },
      },
      credential: {
        tenantName: {
          required: helpers.withMessage("Please input your company name", required),
        },
        contactEmail: {
          email,
          required: helpers.withMessage("Please input your contact email", required),
        },
        email: {
          email,
          required: helpers.withMessage("Please input your email address", required),
        },
        password: {
          required: helpers.withMessage("Please input your password", required),
          Uppercase: helpers.withMessage(
            "Your password must contain an uppercase letter",
            messageCheck.Uppercase
          ),
          Lowercase: helpers.withMessage(
            "Your password must contain an lowercase",
            messageCheck.Lowercase
          ),
          SpecialCharacter: helpers.withMessage(
            "Your password must contain a special character",
            messageCheck.SpecialCharacter
          ),
          Number: helpers.withMessage("Your password must contain a number.", messageCheck.Number),
          minLengthValue: helpers.withMessage(
            "Your password must be at least 10 characters long",
            minLength(10)
          ),
        },
        confirmPassword: {
          required: helpers.withMessage("Please input your confirm password", required),
          sameAsPassword: sameAs(password),
        },
      },
    }
  }
}
