// const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10})/;
// export default function isPasswordStrong(value: any) {
//   return regex.test(value)
// }
const containsUppercase = /[A-Z]/
const containsLowercase = /[a-z]/
const containsNumber = /[0-9]/
const containsSpecial = /[#?!@$%^&*-]/

function Uppercase(value: any) {
  return containsUppercase.test(value)
}
function Lowercase(value: any) {
  return containsLowercase.test(value)
}
function SpecialCharacter(value: any) {
  return containsSpecial.test(value)
}
function Number(value: any) {
  return containsNumber.test(value)
}
export default {   
    Uppercase,
    Lowercase,
    Number,
    SpecialCharacter,
}

