import ChartData from "./ChartData"

export default class ChartDataset {
  labels: string[]
  datasets: ChartData[]

  constructor(labels: string[], chartData: ChartData[]) {
    this.labels = labels
    this.datasets = chartData
  }

  static singleOrange(name: string, labels: string[], data: any[]): ChartDataset {
    return new ChartDataset(labels, [ChartData.orange(name, data)])
  }

  static singleBlue(name: string, labels: string[], data: any[]): ChartDataset {
    return new ChartDataset(labels, [ChartData.blue(name, data)])
  }

  static singleGreen(name: string, labels: string[], data: any[]): ChartDataset {
    return new ChartDataset(labels, [ChartData.green(name, data)])
  }
}
