import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_icon = _resolveComponent("w-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["aspect-square focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-800 disabled:opacity-50", _ctx.classes]),
    type: "button",
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('click', $event)), ["prevent","stop"]))
  }, [
    _createVNode(_component_w_icon, {
      xSmall: _ctx.xSmall,
      small: _ctx.small,
      outlined: _ctx.outlined
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["xSmall", "small", "outlined"])
  ], 10, _hoisted_1))
}