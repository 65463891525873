export default class ChartData {
  readonly label: string
  readonly data: any[]
  readonly borderWidth = 1
  readonly borderColor: string
  readonly pointBackgroundColor: string
  readonly pointBorderColor: string
  readonly backgroundColor: string
  readonly tension = 0.4

  constructor(label: string, data: any[], color: string) {
    this.label = label
    this.data = data
    this.borderColor = color
    this.pointBackgroundColor = color
    this.pointBorderColor = color
    this.backgroundColor = color
  }

  static orange(name: string, data: any[]): ChartData {
    return new ChartData(name, data, "#EF9021")
  }
  static blue(name: string, data: any[]): ChartData {
    return new ChartData(name, data, "#182970")
  }
  static green(name: string, data: any[]): ChartData {
    return new ChartData(name, data, "#006666")
  }
}
