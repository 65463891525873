import { createApp } from "vue"

import App from "./App.vue"
import router from "./router"
import store from "./store"
import floatingVue from "floating-vue"
import VCalendar from "v-calendar"
import Hint from "./components/tooltip/Hint"
import Constant from "./utilities/Constant"
import i18n from "./localize/i18n"

import "@/styles/icons.css"

import "@/styles/vueflow.css"
import "@/styles/floatingvue.css"
import "@/styles/tailwind.css"
import "@/styles/fonts.css"
import "v-calendar/dist/style.css"

const floatingVueThemes = {
  themes: {
    winputpopper: {
      $extend: "dropdown",
    },
  },
}

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VCalendar)
  .use(Constant)
  .use(Hint)
  .use(floatingVue, floatingVueThemes)

app.config.unwrapInjectedRef = true

app.mount("#app")
