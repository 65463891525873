import Environment from "../models/Environment"
import { client, apiError } from "../../api/_client"
import FlowEnvironment from "@/flow/models/FlowEnvironment"

async function getEnvironments(): Promise<Environment[]> {
  try {
    const response = await client.get("environment")
    return response.data.records.map((each: any) => Environment.fromResponse(each))
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getEnvironmentsByFlowId(flowId: string): Promise<FlowEnvironment[]> {
  try {
    const response = await client.get(`flow_environment?$filter=idflow eq '${flowId}'&$expand=environment`)
    return response.data.records.map((each: any) => FlowEnvironment.fromResponse(each))
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getEnvironment(id: string): Promise<Environment> {
  try {
    const response = await client.get(`environment(${id})`)
    return Environment.fromResponse(response.data)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function createEnvironment(environment: Environment): Promise<Environment> {
  try {
    const headers = { Prefer: "return=representation" }
    const response = await client.post("environment", environment.encode(), { headers })
    return Environment.fromResponse(response.data)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function updateEnvironment(environment: Environment): Promise<void> {
  const data = environment.encode()
  try {
    await client.patch(`environment(${environment.id})`, data)
  } catch (error: any) {
    throw apiError(error, {})
  }
}

async function deleteEnvironment(id: string): Promise<void> {
  try {
    await client.delete(`environment(${id})`)
  } catch (error) {
    throw apiError(error, {})
  }
}

export { getEnvironments, getEnvironmentsByFlowId, getEnvironment, createEnvironment, updateEnvironment, deleteEnvironment }
