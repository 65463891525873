import router from "@/router"
function setLanguageToSession(locale: any) {
  localStorage.setItem("lang", locale)
  router.push({ name: router.currentRoute.value.name! })
}
function getUserDefault() {
  if (localStorage.getItem("lang") === null) {
    return "en"
  } else {
    return localStorage.getItem("lang")
  }
}
export { setLanguageToSession, getUserDefault }
