import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_icon = _resolveComponent("w-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_w_icon, {
      class: "text-gray-300",
      small: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("help")
      ]),
      _: 1
    })
  ])), [
    [_directive_tooltip, _ctx.$hint(_ctx.content)]
  ])
}