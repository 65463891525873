
import { defineComponent } from "vue"
import WIcon from "../misc/WIcon.vue"

export default defineComponent({
  components: { WIcon },
  props: {
    content: null as null | Translatable
  },
})
