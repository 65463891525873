import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { class: "flex-1 flex flex-col h-screen overflow-auto" }
const _hoisted_3 = {
  key: 1,
  class: "grid h-screen place-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sidebar = _resolveComponent("app-sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_w_spinner = _resolveComponent("w-spinner")!

  return (_ctx.$store.getters.isConstantLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_app_sidebar),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_view, { class: "flex-1" }),
          _createVNode(_component_app_footer)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_w_spinner, {
          small: "",
          class: "border-gray-600"
        })
      ]))
}