import { toRecord } from "@/utilities/functions"

export default class Datasource {
  private constructor(
    public readonly id: string,
    public readonly schemas: Record<string, Schema>
  ) {}

  static decode(data: any): Datasource {
    return new Datasource(
      data.iddata_source,
      toRecord(
        data.schemas.map((each: any) => Schema.decode(each)),
        "logicalName"
      )
    )
  }
}

// export default class Datasource {
//   private _id: string | null = null
//   get id(): string | null {
//     return this._id
//   }

//   status: boolean

//   readonly displayName: string
//   readonly technicalName: string
//   readonly key: string

//   readonly platformId: string
//   description: string | null

//   readonly schemas: Schema[]

//   private constructor(params: New<Datasource>) {
//     this.status = params.status ?? true
//     this.displayName = params.displayName!
//     this.technicalName = params.technicalName!
//     this.key = params.key!
//     this.platformId = params.platformId!
//     this.description = params.description ?? null
//     this.schemas = params.schemas ?? []
//   }

//   static decode(data: any): Datasource {
//     const datasource = new Datasource({
//       status: data.status == 1,
//       displayName: data.displayname,
//       technicalName: data.technical_name,
//       key: data.key,
//       platformId: data.idplatform,
//       description: data.description,
//       schemas: data.schemas.map((each: any) => Schema.decode(each)),
//     })
//     datasource._id = data.iddata_source
//     return datasource
//   }
// }

class Schema {
  private constructor(
    public readonly id: string,
    public readonly logicalName: string,
    public readonly displayName: Translatable,
    public readonly description: Translatable,
    public readonly type: number | undefined,
    public readonly format: string | undefined,
    public readonly platformId: string | null,
    public readonly listenerId: string | null,
    public readonly datasourceId: string | null,
    public readonly content: any | undefined
  ) {}

  static decode(data: any): Schema {
    return new Schema(
      data.idschema,
      data.name,
      JSON.parse(data.display),
      JSON.parse(data.description),
      data.type,
      data.format,
      data.idplatform ?? null,
      data.idlistener ?? null,
      data.iddata_source ?? null,
      !data.content ? undefined : JSON.parse(data.content)
    )
  }
}



// export default Schema
// export { Content, FixedMappingInfo, MappingInfo, MatchingInfo }
