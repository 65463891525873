import Listener from "./Listener"
import Datasource from "./Datasource"
import { Tag } from "@/tag"
import { ConnectionSchema } from "@/connection/models/Connection"

import { toRecord } from "@/utilities/functions"

export default class Platform {
  private constructor(
    public readonly id: string,
    public readonly key: string,
    public readonly name: string,
    public readonly listeners: Record<string, Listener>,
    public readonly datasources: Record<string, any>,
    public readonly tags: Tag[],
    public readonly testable: boolean,
    public readonly connectionSchemas: Record<string, ConnectionSchema>
  ) {}

  get logo() {
    try {
      return require(`@/assets/logos/${this.name}.png`)
    } catch (_) {
      return require(`@/assets/logos/wiresk.png`)
    }
  }

  static decode(data: any): Platform {
    return new Platform(
      data.idplatform,
      data.key,
      data.name,
      toRecord(
        data.listeners.map((each: any) => Listener.decode(each)),
        "id"
      ),
      toRecord(
        data.data_sources.map((each: any) => Datasource.decode(each)),
        "id"
      ),
      data.tags.map((each: any) => Tag.fromResponse(each)),
      data.testable,
      toRecord(
        data.schemas.map((each: any) => ConnectionSchema.decode(each)),
        "id"
      )
    )
  }
}
