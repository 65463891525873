import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "space-x-1 flex items-center w-fit" }
const _hoisted_2 = { class: "text-gray-800 tracking-wide text-[15px]" }
const _hoisted_3 = { class: "bg-white p-2 w-full border rounded" }
const _hoisted_4 = { class: "flex flex-wrap" }
const _hoisted_5 = {
  key: 0,
  class: "text-sm text-red-600 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_hint_icon = _resolveComponent("w-hint-icon")!
  const _component_w_icon = _resolveComponent("w-icon")!
  const _component_w_button = _resolveComponent("w-button")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
      (_ctx.hint)
        ? (_openBlock(), _createBlock(_component_w_hint_icon, {
            key: 0,
            content: _ctx.hint
          }, null, 8, ["content"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_date_picker, {
        modelValue: _ctx.selected.date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected.date) = $event)),
        color: "red",
        mode: "date",
        "is-required": "",
        "min-date": _ctx.minDate ?? null
      }, {
        default: _withCtx(({ togglePopover, hidePopover }) => [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dates, (date) => {
              return (_openBlock(), _createBlock(_component_w_button, {
                small: "",
                key: date.date.getTime(),
                class: "flex items-center bg-primary-900 text-sm font-semibold h-8 px-2 m-1 rounded-lg border-2 border-transparent focus:outline-none",
                onClick: ($event: any) => (_ctx.dateSelected($event, date, togglePopover)),
                ref_for: true,
                ref: "chip"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(date.date.toLocaleDateString()) + " ", 1),
                  _createVNode(_component_w_icon, {
                    small: "",
                    class: "ml-1 -mr-1",
                    onClick: _withModifiers(($event: any) => (_ctx.removeDate(date, hidePopover)), ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("close")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128)),
            _createVNode(_component_w_button, {
              small: "",
              class: "flex items-center bg-primary-900 text-sm text-white-100 h-8 px-2 m-1 rounded-lg border-2 border-transparent focus:outline-none",
              onClick: _ctx.addDate
            }, {
              default: _withCtx(() => [
                _createTextVNode(" + Add ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "min-date"])
    ]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 64))
}