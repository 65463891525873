import Platform from "../models/Platform"
import Listener from "../models/Listener"
import { toRecord } from "@/utilities/functions"

export default {
  state: {
    platforms: undefined as Record<string, Platform> | undefined,
    orphanListeners: undefined as Record<string, Listener> | undefined,
  },
  mutations: {
    setPlatforms(state: any, value: Platform[]) {
      state.platforms = toRecord(value, "id")
    },
    setOrphanListeners(state: any, value: Listener[]) {
      state.orphanListeners = toRecord(value, "id")
    },
  },
  getters: {
    isLoaded(state: any) {
      return state.platforms !== undefined && state.orphanListeners !== undefined
    },
    platforms: (state: any): Platform[] => Object.values(state.platforms),
    platformById:
      (state: any) =>
      (id: string): Platform =>
        state.platforms[id],
    listenerPlatforms: (state: any): Platform[] => {
      const platforms = Object.values(state.platforms) as Platform[]
      return platforms.filter((each) => Object.keys(each.listeners).length > 0)
    },
    datasourcePlatforms: (state: any): Platform[] => {
      const platforms = Object.values(state.platforms) as Platform[]
      return platforms.filter((each) => Object.keys(each.datasources).length > 0)
    },
    orphanListeners: (state: any): Record<string, Listener> => state.orphanListeners,
  },
}
