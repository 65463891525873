import { client, unauthedClient, apiError } from "./_client"
import User from "@/models/user/User"
import Country from "@/models/user/Country"

async function getProfile(): Promise<User> {
  try {
    const myId = (await client.post("whoAmI")).data.callerId
    const response = await client.get(`user(${myId})?$expand=tenant`)
    return User.fromResponse(response.data)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function updateProfile(user: User): Promise<void> {
  try {
    await client.patch(`user(${user.id})`, user.oDataJson())
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getCountries(): Promise<Country[]> {
  try {
    const response = await unauthedClient.get("country?$orderby=display_name")
    return response.data.records.map((each: any) => Country.fromResponse(each))
  } catch (error) {
    throw apiError(error, {})
  }
}

export { getProfile, updateProfile, getCountries }
