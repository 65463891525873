import i18n from "./i18n"
export  const Localize = {
  install: (App: any) => {
    App.config.globalProperties.$localize = (content: Translatable) => {
      if (content != null) {
        const translated = i18n.global.t(content.key)
        if (translated === content.key) {
          return content.default || content.key
        }
        return translated
      } 
    }
  },
}

export default Localize
