import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass([
      'rounded-md tracking-wide text-primary-900 hover:text-primary-800',
      'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-700',
      _ctx.strong ? 'font-medium' : '',
    ]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push(_ctx.to)), ["prevent"]))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}