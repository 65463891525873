
import { defineComponent } from "vue"
import { WForm, WTextField, WButton } from "@/components/form"
import { getProfile, updateProfile, User } from "@/user"
import { isImpersonating } from "@/auth"
export default defineComponent({
  emits: ["discard"],
  data() {
    return {
      profileInfo: {} as User,
    }
  },
  computed: {
    isImpersonating(): boolean {
      return isImpersonating()
    },
  },
  methods: {
    async submit() {
      try {
        await updateProfile(this.profileInfo)
      } catch (error) {
        console.log(error)
      }
    },
    async getProfileUser() {
      this.profileInfo = await getProfile()
    },
  },
  mounted() {
    this.getProfileUser()
  },
  components: { WForm, WTextField, WButton },
})
