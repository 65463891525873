
import { defineComponent } from "vue"
export default defineComponent({
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    indent: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes() {
      return {
        'px-4': this.indent,
        'py-4': !this.dense,
        'py-2': this.dense
      }
    }
  }
})
