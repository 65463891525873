
import { defineComponent } from "vue"
import { DialogDescription } from "@headlessui/vue"
import WDialog from "./WDialog.vue"
import { WAlert, WAlertDescription } from "../alert"

export default defineComponent({
  props: {
    title: String,
    description: String,
    errorMessage: String,
  },
  components: {
    WDialog,
    DialogDescription,
    WAlert,
    WAlertDescription,
  },
})
