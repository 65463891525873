
import { defineComponent } from "vue"
import { useVuelidate } from "@vuelidate/core"
import messageCheck from "@/validator"
import { required, helpers, sameAs, minLength } from "@vuelidate/validators"

import { getProfile } from "@/user"
import { changePassword } from "@/auth"

import { WForm, WTextField, WButton } from "@/components/form"

export default defineComponent({
  data() {
    return {
      currentPassword: null as null | string,
      newPassword: null as null | string,
      confirmPassword: null as null | string,
      mfaEnabled: undefined as undefined | boolean,
      mfaCode: null as null | string,
      error: null as null | Translatable,
      isLoading: false,
    }
  },
  mounted() {
    this.checkMfa()
  },
  methods: {
    async checkMfa() {
      this.mfaEnabled = (await getProfile()).mfaEnabled
    },
    async changePassword() {
      try {
        this.error = null
        this.isLoading = true
        const data = {
          password: this.currentPassword!,
          newPassword: this.newPassword!,
          mfaCode: this.mfaCode ?? undefined,
        }
        await changePassword(data)
      } catch (error: any) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    const validations = {
      currentPassword: {
        required: helpers.withMessage("Please input your current password", required),
      },
      newPassword: {
        required: helpers.withMessage("Please input your new password", required),
        Uppercase: helpers.withMessage(
          "Your password must contain an uppercase letter",
          messageCheck.Uppercase
        ),
        Lowercase: helpers.withMessage(
          "Your password must contain an lowercase",
          messageCheck.Lowercase
        ),
        SpecialCharacter: helpers.withMessage(
          "Your password must contain a special character",
          messageCheck.SpecialCharacter
        ),
        Number: helpers.withMessage("Your password must contain a number.", messageCheck.Number),
        minLengthValue: helpers.withMessage(
          "Your password must be at least 10 characters long",
          minLength(10)
        ),
      },
      confirmPassword: {
        required: helpers.withMessage("Please confirm your password", required),
        sameAsPassword: sameAs(this.newPassword),
      },
    } as any
    if (this.mfaEnabled === true) {
      validations.mfaCode = {
        required: helpers.withMessage("Please input MFA code", required),
      }
    }
    return validations
  },
  components: {
    WForm,
    WTextField,
    WButton,
  },
})
