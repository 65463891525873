import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-x-1 mb-1"
}
const _hoisted_2 = { class: "text-gray-800 tracking-wide text-[15px]" }
const _hoisted_3 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "grow truncate text-left" }
const _hoisted_6 = { class: "max-h-96 w-fit list-none py-2 overflow-y-scroll" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "absolute inset-y-0 left-0 items-center w-10 material-icons-round text-primary-900 place-items-center justify-around flex"
}
const _hoisted_9 = {
  key: 1,
  class: "mt-2 flex items-center gap-1"
}
const _hoisted_10 = { class: "text-sm text-orange-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_icon = _resolveComponent("w-icon")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
          (!_ctx.optional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_dropdown, { placement: "right" }, {
      popper: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return _withDirectives((_openBlock(), _createElementBlock("li", {
              key: option,
              class: "relative pl-10 pr-4 py-2 text-sm cursor-pointer select-none hover:bg-gray-200",
              onClick: ($event: any) => (_ctx.updateSelected(option))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.getDisplay(option)), 1),
              (_ctx.selectedOption === option)
                ? (_openBlock(), _createElementBlock("i", _hoisted_8, " check "))
                : _createCommentVNode("", true)
            ], 8, _hoisted_7)), [
              [_directive_close_popper]
            ])
          }), 128))
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          disabled: _ctx.disabled,
          class: _normalizeClass([
          'py-2 pl-3 pr-2 w-full flex items-center gap-2 rounded-md border border-gray-300 group disabled:bg-gray-100',
          'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-700',
        ])
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getDisplay(_ctx.selectedOption)), 1),
          _createVNode(_component_w_icon, { class: "text-gray-500 group-hover:text-gray-900" }, {
            default: _withCtx(() => [
              _createTextVNode("unfold_more")
            ]),
            _: 1
          })
        ], 8, _hoisted_4)
      ]),
      _: 1
    }),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}