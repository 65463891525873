import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_text_field = _resolveComponent("w-text-field")!
  const _component_w_button = _resolveComponent("w-button")!
  const _component_w_form = _resolveComponent("w-form")!

  return (_openBlock(), _createBlock(_component_w_form, {
    skeletonLoading: _ctx.mfaEnabled === undefined,
    validator: _ctx.v$,
    errorMessage: _ctx.$localize(_ctx.error),
    onSubmit: _ctx.changePassword
  }, {
    default: _withCtx(({ lazyError }) => [
      _createVNode(_component_w_text_field, {
        label: "Current Password",
        modelValue: _ctx.v$.currentPassword.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.currentPassword.$model) = $event)),
        type: "password",
        errorMessage: lazyError(_ctx.v$.currentPassword)
      }, null, 8, ["modelValue", "errorMessage"]),
      (_ctx.mfaEnabled)
        ? (_openBlock(), _createBlock(_component_w_text_field, {
            key: 0,
            label: "MFA Code",
            modelValue: _ctx.v$.mfaCode.$model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.mfaCode.$model) = $event)),
            errorMessage: lazyError(_ctx.v$.mfaCode)
          }, null, 8, ["modelValue", "errorMessage"]))
        : _createCommentVNode("", true),
      _createVNode(_component_w_text_field, {
        label: "New Password",
        modelValue: _ctx.v$.newPassword.$model,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.newPassword.$model) = $event)),
        type: "password",
        errorMessage: lazyError(_ctx.v$.newPassword)
      }, null, 8, ["modelValue", "errorMessage"]),
      _createVNode(_component_w_text_field, {
        label: "Confirm Password",
        modelValue: _ctx.v$.confirmPassword.$model,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.confirmPassword.$model) = $event)),
        type: "password",
        errorMessage: lazyError(_ctx.v$.confirmPassword)
      }, null, 8, ["modelValue", "errorMessage"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_w_button, {
        submitter: "",
        loading: _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isLoading ? "Submitting" : "Submit"), 1)
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_w_button, { outlined: "" }, {
        default: _withCtx(() => [
          _createTextVNode("Cancel")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["skeletonLoading", "validator", "errorMessage", "onSubmit"]))
}