import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-center space-x-2" }
const _hoisted_2 = { class: "px-2 py-1 rounded-full text-gray-500 bg-gray-200 text-xs flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_icon = _resolveComponent("w-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.item[_ctx.itemText]) + " ", 1),
      _createElementVNode("button", {
        class: "bg-gray-500 text-gray-200 hover rounded-full focus:outline-none ml-2 w-[16px] h-[16px]",
        type: "button",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('onRemove', _ctx.item[_ctx.returnKey])), ["stop"]))
      }, [
        _createVNode(_component_w_icon, { "x-small": "" }, {
          default: _withCtx(() => [
            _createTextVNode("close")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}