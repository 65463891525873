
import { defineComponent } from "vue"
import WIcon from "../misc/WIcon.vue"

export default defineComponent({
  emits: ["click"],
  props: {
    disabled: Boolean,
    square: Boolean,
    small: Boolean,
    primary: Boolean,
    plain: Boolean,
    gray: Boolean,
    outlined: Boolean,
    shy: Boolean,
    xSmall: Boolean,
  },
  computed: {
    isPrimary() {
      return !this.shy && this.primary
    },
    isPlain() {
      return !this.shy && this.plain
    },
    isGray() {
      return !this.shy && this.gray
    },
    isShy() {
      return this.shy || !(this.primary || this.plain || this.gray)
    },
    classes() {
      return {
        "rounded-full": !this.square,
        rounded: this.square,
        "text-white bg-primary-900 enabled:hover:bg-primary-800": this.isPrimary,
        "text-primary-900 enabled:hover:text-primary-800": this.isPlain,
        "text-gray-800 bg-gray-200 enabled:hover:text-gray-900 enabled:hover:bg-gray-300":
          this.isGray,
        "text-gray-600 enabled:hover:text-gray-900": this.isShy,
        "h-6": this.xSmall,
        "h-10": !this.xSmall,
      }
    },
  },
  components: { WIcon },
})
