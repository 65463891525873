export default class Country {
  private constructor(
    public readonly id: string,
    public readonly displayName: string,
    public readonly fullName: string,
    public readonly defaultTenantId: string,
    public readonly isoAlpha2: string,
    public readonly phonePrefix: string
  ) {}

  static fromResponse(data: any): Country {
    return new Country(
      data.idcountry,
      data.display_name,
      data.full_name,
      data.iddefault_tenant,
      data.iso_alpha2,
      data.prefix
    )
  }
}
