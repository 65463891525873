import { client, apiError } from "@/api/_client"

import DataPoint from "../models/DataPoint"

async function getDataPoints(): Promise<DataPoint[]> {
  try {
    const response = await client.get("consolidated_metric")
    return response.data.records.map((each: any) => DataPoint.fromResponse(each))
  } catch (error) {
    throw apiError(error, {})
  }
}

export { getDataPoints }
