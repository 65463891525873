import { ChartDataset } from "@/components/chart"

export default class ChartDataProvider {
  storeData: object[]

  constructor(getData: object[]) {
    this.storeData = getData
  }

  getBetween(startDate: Date, endDate: Date) {
    const labelFilter = this.storeData.filter((datevalue: any) => {
      const valueDate = new Date(datevalue.periodStart)
      if (startDate <= valueDate && valueDate <= endDate) {
        return true
      } else {
        return false
      }
    })

    const sortLabel = labelFilter.sort((a: any, b: any) => {
      const dateA = new Date(a.periodStart).getTime()
      const dateB = new Date(b.periodStart).getTime()
      return dateA >= dateB ? 1 : -1
    })

    const labels = sortLabel.map((element: any) =>
      element.periodStart.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    )

    const loopStartDate = new Date(startDate)
    while (loopStartDate <= endDate) {
      const label = loopStartDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      if (!labels.includes(label)) {
        labels.push(label)
      }
      loopStartDate.setDate(loopStartDate.getDate() + 1)
    }

    const flowUsage = labelFilter.map((element: any) => element.flowUsage)
    const activeTenant = labelFilter.map((element: any) => element.activeTenant)
    const invoices = labelFilter.map((element: any) => element.invoicing)

    return {
      chartInvoice: ChartDataset.singleOrange("Invoice", labels, invoices),
      chartFlowUsage: ChartDataset.singleBlue("FLow Usage", labels, flowUsage),
      chartActiveTenant: ChartDataset.singleGreen("Active Tenant", labels, activeTenant),
    }
  }
}
