import { nextTick } from "vue"
import { createI18n } from "vue-i18n"
import { getAppTranslation } from "../api/translation"

const i18n = createI18n({
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  inheritLocale: true,
  globalInjection: true,
  allowComposition: true,
})
export async function translateTo(locale: string) {
  if (locale !== i18n.global.locale) {
    const messages = await getAppTranslation(locale)
    i18n.global.setLocaleMessage(locale, messages)
    i18n.global.locale = locale
    document.querySelector("html")!.setAttribute("lang", locale)
  }
  return nextTick()
}

export default i18n
