
import { defineComponent, PropType } from "vue"
import WIcon from "../misc/WIcon.vue"

export default defineComponent({
  // Note: Having 'close' in emits making it not available in this.$attrs
  // emits: ["close"],
  props: {
    mode: {
      type: String as PropType<"success" | "error" | "info" | "warning" | "default">,
      default: "default",
    },
  },

  computed: {
    classType() {
      return {
        "text-green-700 bg-green-50": this.mode === "success", // Light green
        "text-red-700 bg-red-50": this.mode === "error", // Light red
        "text-blue-700 bg-blue-50": this.mode === "info", // Light blue
        "text-amber-700 bg-amber-50": this.mode === "warning", // Light blue
        "text-gray-700 bg-gray-50": this.mode === "default",
      }
    },

    iconType() {
      switch (this.mode) {
        case "success":
          return "check_circle" // Circle with tick
        case "error":
          return "cancel" // Circle with cross
        case "info":
          return "info"
        case "warning":
          return "report_problem"
        default:
          return null
      }
    },
  },
  components: { WIcon },
})
