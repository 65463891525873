export default class ConnectionEnvironment {
  private _id: string | null
  get id(): string | null {
    return this._id
  }

  readonly connectionId: string | undefined
  readonly tagId: string | undefined
  readonly environmentId: string

  constructor(params: New<ConnectionEnvironment>) {
    this._id = null
    this.connectionId = params.connectionId
    this.environmentId = params.environmentId!
    this.tagId = params.tagId
  }

  static fromResponse(data: any): ConnectionEnvironment {
    const connectionEnvironment = new ConnectionEnvironment({
      connectionId: data.idconnection,
      environmentId: data.idenvironment,
      tagId: data.idtag
    })
    connectionEnvironment._id = data.idconnection_environment
    return connectionEnvironment
  }

  oDataJson(): any {
    const json = {
      idenvironment: `environment(${this.environmentId})`
    } as any
    if (this.tagId) {
      json.idtag = `tag(${this.tagId})`
    }
    if (this.connectionId) {
      json.idconnection = `connection(${this.connectionId})`
    }
    return json
  }
}