import Wt from "../../localize/Wt"
const hint = {
  install(App: any) {
    Wt.install(App);
    App.config.globalProperties.$hint = (content: Translatable, delay:number) => {
      const translated = App.config.globalProperties.$localize(content);
      return {
        content: translated,
        popperClass: "text-xs max-w-xs z-[10001] w-fit",
        delay: {show:delay * 1000, hide: 0 },
      };
    };
  },
}

export default hint
