export default class Encoder {
  private _json: any = {}
  constructor(private readonly _obj: any) {}

  json(): any {
    return Object.keys(this._json).length === 0 ? undefined : this._json
  }

  encodeValue(value: any, key: string): Encoder {
    if (value !== undefined) {
      this._json[key] = value
    }
    return this
  }

  encodeProps(...props: string[]): Encoder {
    props.forEach((each) => {
      this.encodeProp(each)
    })
    return this
  }

  encodeProp(prop: string, key?: string): Encoder {
    const value = this.getValue(this._obj, prop)
    if (this._obj._o === null) {
      if (value !== undefined && value !== null) {
        this._json[key ?? prop] = this._encodeValue(value)
      }
      return this
    }
    const _oValue = this.getValue(this._obj._o, prop)
    if (_oValue !== undefined) {
      if (value !== _oValue) {
        this._json[key ?? prop] = this._encodeValue(value)
      }
    }
    return this
  }

  encodeRef(relationship: string, prop: string, key?: string): Encoder {
    const id = this.getValue(this._obj, prop)
    if (this._obj._o === null) {
      if (id !== undefined && id !== null) {
        this._json[key ?? prop] = relationship + `(${id})`
      }
      return this
    }
    const _oId = this.getValue(this._obj._o, prop)
    if (_oId !== undefined) {
      if (id !== _oId) {
        this._json[key ?? prop] = relationship + `(${id})`
      }
    }
    return this
  }

  // TODO: delete
  encodeSecret(prop: string, key?: string): Encoder {
    if (this._obj._o === null) {
      if (this._obj[prop]) {
        this._json[key ?? prop] = this._obj[prop]
      }
      return this
    }
    if (this._obj._o[prop] !== undefined) {
      if (this._obj[prop] !== (this._obj._o[prop] ? "******" : null)) {
        this._json[key ?? prop] = this._obj[prop]
      }
    }
    return this
  }

  private _encodeValue(value: any) {
    return value === true ? 1 : value === false ? 0 : value
  }

  private getValue(obj: any, prop: string) {
    return !prop.includes(".")
      ? obj[prop]
      : prop.split(".").reduce((v, e) => {
          v = v[e]
          return v
        }, obj)
  }
}
