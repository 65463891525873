
import { defineComponent, PropType } from "vue"
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue"

import WInputWrapper from "./WInputWrapper.vue"
import { WSpinner, WIcon } from "@/components/misc"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    id: String,
    label: String,
    fixed: Boolean,
    modelValue: {
      type: null as unknown as PropType<any | null>,
      required: true,
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    returnKey: String,
    itemKey: String,
    itemText: {
      type: [String, Function],
      default: "text",
    },
    itemSubText: String,
    placeholder: String,
    hint: null as null | Translatable,
    warningMessage: String,
    errorMessage: String,
    placement: {
      type: String,
      default: "right",
    },
    plain: Boolean,
    loading: Boolean,
    disabled: Boolean,
    optional: Boolean,
  },
  data() {
    return {
      search: null as string | null,
      rect: undefined as any,
      inc: 1 as number,
    }
  },
  mounted() {
    this.setRect()
    this.inc++
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
    selectedItem(): any {
      if (this.inc > 0) {
        return this.returnKey
          ? this.items.find((each) => each[this.returnKey!] === this.modelValue)!
          : this.modelValue
      } else {
        return this.modelValue
      }
    },
    searchedItems(): any[] {
      if (!this.search || this.search === "") {
        return this.items
      }
      const search = this.search.toLowerCase().replace(/\s+/g, "")
      return this.items.filter((each) => {
        return (
          this.getItemText(each)?.toLowerCase().replace(/\s+/g, "").includes(search) ||
          (this.itemSubText &&
            each[this.itemSubText] &&
            each[this.itemSubText].toLowerCase().replace(/\s+/g, "").includes(search))
        )
      })
    },
    optionsPosition(): string | undefined {
      if (!this.bottomGap) {
        return undefined
      }
      if (this.bottomGap > 440) {
        return "bottom"
      }
      return window.innerWidth - this.rect.right > 400 ? "right" : "left"
    },
    bottomGap(): number | undefined {
      if (!this.fixed) {
        return 450
      }
      if (!this.rect) {
        return undefined
      }
      return window.innerHeight - this.rect.bottom
    },
  },
  methods: {
    getItemText(item: any) {
      if (typeof this.itemText === "string") {
        return item[this.itemText] ?? item
      } else {
        return this.itemText(item)
      }
    },
    setRect() {
      const combobox = this.$refs.combobox as any
      this.rect = combobox.$el.getBoundingClientRect()
    },
    updateSelected(item: any) {
      const value = this.returnKey ? item?.[this.returnKey] ?? null : item
      this.$emit("update:modelValue", value)
    },
  },
  components: {
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    WInputWrapper,
    WSpinner,
    WIcon,
  },
})
