import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "divide-y"
}
const _hoisted_2 = { class: "flex items-center gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_skeleton = _resolveComponent("w-skeleton")!
  const _component_w_icon = _resolveComponent("w-icon")!
  const _component_w_button = _resolveComponent("w-button")!
  const _component_w_action_dialog = _resolveComponent("w-action-dialog")!

  return (!_ctx.tenants)
    ? (_openBlock(), _createBlock(_component_w_skeleton, {
        key: 0,
        type: "item#3"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenants, (tenant) => {
          return (_openBlock(), _createElementBlock("div", {
            key: tenant.id,
            class: "py-3 flex items-center gap-4 justify-between"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_w_icon, { outlined: "" }, {
                default: _withCtx(() => [
                  _createTextVNode("store")
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(tenant.name), 1)
            ]),
            _createVNode(_component_w_button, {
              small: "",
              outlined: "",
              onClick: ($event: any) => (_ctx.impersonateId = tenant.id)
            }, {
              default: _withCtx(() => [
                _createTextVNode("Impersonate")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]))
        }), 128)),
        _createVNode(_component_w_action_dialog, {
          title: "Impersonate",
          description: "Confirm you want to impersonate this tenant",
          modelValue: _ctx.impersonateId !== null,
          "onUpdate:modelValue": _ctx.cancelImpersonation,
          errorMessage: _ctx.error
        }, {
          default: _withCtx(() => [
            _createVNode(_component_w_button, {
              onClick: _ctx.impersonate,
              loading: _ctx.isLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isLoading ? "Logging In" : "Impersonate"), 1)
              ]),
              _: 1
            }, 8, ["onClick", "loading"]),
            _createVNode(_component_w_button, {
              outlined: "",
              onClick: _ctx.cancelImpersonation
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cancel")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["modelValue", "onUpdate:modelValue", "errorMessage"])
      ]))
}