import { client, apiError } from "../../api/_client"
import Connection, {ConnectionTestResponse} from "../models/Connection"
import ConnectionTag from "../models/ConnectionTag"

async function getConnections(): Promise<Connection[]> {
  try {
    const response = await client.get("connection")
    return response.data.records.map((each: any) => Connection.fromResponse(each))
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getConnection(id: string): Promise<Connection> {
  try {
    const response = await client.get(`connection(${id})?$expand=connection_environments`)
    return Connection.fromResponse(response.data)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function createConnection(connection: Connection): Promise<string> {
  try {
    const response = await client.post("connection", connection.encode())
    return response.headers["odata-entityid"]
  } catch (error) {
    throw apiError(error, {})
  }
}

async function updateConnection(connection: Connection): Promise<void> {
  try {
    const data = connection.encode()
    if (data) {
      await client.patch(`connection(${connection.id})`, data)
    }
  } catch (error) {
    throw apiError(error, {})
  }
}

async function deleteConnection(id: string): Promise<void> {
  try {
    await client.delete(`connection(${id})`)
  } catch (error) {
    throw apiError(error, {})
  }
}

async function getConnectionTags(params: {
  platformId: string
  environmentId: string
}): Promise<ConnectionTag[]> {
  try {
    const connSelect = "$select=idconnection,name"
    const connFilter = `$filter=idplatform eq '${params.platformId}'`
    const expand = `$expand=connection_environments($filter=idenvironment eq '${params.environmentId}')`
    const response = await client.get(`connection?${connSelect}&${connFilter}&${expand}`)
    return response.data.records.map((each: any) => ConnectionTag.fromConnectionResponse(each))
  } catch (error) {
    throw apiError(error, {})
  }
}


async function testConnection(connection: Connection): Promise<ConnectionTestResponse> {
  try {
    const response = await client.post('testConnection', connection.encode())
    return ConnectionTestResponse.fromResponse(response.data)
  } catch (error) {
    throw apiError(error, {})
  }
}

export {
  getConnections,
  getConnectionTags,
  getConnection,
  createConnection,
  updateConnection,
  deleteConnection,
  testConnection
}
